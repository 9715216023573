import { Box, Button, Tooltip } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const RepaintButton = ( { onClick, disabled = true } ) => {
  return (
    <Box style={{ marginLeft: '4px' }}>
      <Tooltip title="Refresh layers and reapply filters">
        <Button
          variant="outlined"
          onClick={onClick}
          disabled={disabled}
        >
          <AutorenewIcon
            fontSize="small"
            sx={{ marginRight: '4px' }}
          />
          Repaint Image
        </Button>
      </Tooltip>
    </Box>
  );
};

export default RepaintButton;