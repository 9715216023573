import { useMemo } from 'react';
import BeeSwarmGraph from './BeeSwarmGraph';
import BioMarkerDataStringGraph, { Datum } from './BiomarkerDataStringGraph';

type BioMarkerDataType = {
    Description: string;
    dataType: 'standard' | 'tenx';
    [ key: string ]: number | string | boolean | undefined;
    Accession?: number;
};

interface BioMarkerGraphRenderProps {
    biomarkerData: BioMarkerDataType[];
    biomarkerName: keyof Omit<BioMarkerDataType, 'Description' | 'dataType' | 'Accession'> | string;
    compAnnoColor?: string; //* comp prefix means comparison
    compBiomarkerData?: BioMarkerDataType[];
}
const FREQUENCY_VISUALIZATION_LIMIT = 15;

const BioMarkerGraphRender = ({ biomarkerData, biomarkerName, compBiomarkerData, compAnnoColor }: BioMarkerGraphRenderProps) => {
    const firstBiomarkerValue = biomarkerData[ 0 ][ biomarkerName ];

    if (compBiomarkerData?.length) {
        const isInvalidComparison = (
            !(biomarkerName in compBiomarkerData[ 0 ]) ||
            typeof firstBiomarkerValue !== typeof compBiomarkerData[ 0 ][ biomarkerName ]
        );
        if (isInvalidComparison) {
            compBiomarkerData = undefined;
        }
    }
    const isNumericBiomarker = useMemo(() => (
        firstBiomarkerValue !== undefined &&
        typeof firstBiomarkerValue === 'number'
    ), [ firstBiomarkerValue ]);

    const isStringBiomarker = useMemo(() => (
        firstBiomarkerValue !== undefined &&
        typeof firstBiomarkerValue === 'string'
    ), [ firstBiomarkerValue ]);

    return (
        <>
            { isNumericBiomarker && (
                <BeeSwarmGraph
                    biomarkerData={ biomarkerData }
                    biomarkerName={ biomarkerName }
                    compBiomarkerData={ compBiomarkerData }
                    compAnnoColor={ compAnnoColor }
                />
            ) }

            { isStringBiomarker && (
                <BioMarkerDataStringGraph
                    wordFrequency={ generateWordFrequency(
                        biomarkerData
                            .map(b => b[ biomarkerName as string ])
                            .filter((v): v is string => typeof v === 'string')
                    ) }
                    compWordFrequency={ compBiomarkerData ? generateWordFrequency(
                        compBiomarkerData
                            .map(b => b[ biomarkerName as string ])
                            .filter((v): v is string => typeof v === 'string')
                    ) : [] }
                    compAnnoColor={ compAnnoColor }
                />
            ) }

        </>
    );
};

// Helper to generate word frequency for WordCloud
const generateWordFrequency = (columnData: string[]): Datum[] => {
    const wordCount = new Map<string, number>();

    columnData.forEach((text) => {
        let start = 0;
        const len = text.length;

        while (start < len) {
            const match = /[a-zA-Z0-9|_.-]+/g.exec(text.slice(start));
            if (!match) break;

            const word = match[ 0 ].toLowerCase();
            if (word.length > 1) {
                wordCount.set(word, (wordCount.get(word) || 0) + 1);
            }
            start += match.index + match[ 0 ].length;
        }
    });

    const topWords = Array.from(wordCount.entries())
        .reduce((heap, [ word, value ]) => {
            if (heap.length < FREQUENCY_VISUALIZATION_LIMIT) {
                heap.push({ text: word, value });
                heap.sort((a, b) => b.value - a.value);
            } else if (value > heap[ heap.length - 1 ].value) {
                heap[ heap.length - 1 ] = { text: word, value };
                heap.sort((a, b) => b.value - a.value);
            }
            return heap;
        }, [] as Datum[]);

    return topWords;
};

export default BioMarkerGraphRender;