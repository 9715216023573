import { MCDFile } from 'types/MCDTypes';
import { api } from './api';

interface UploadSlideMultilayerConfigRequest {
    slide: number;
    roi: number;
    layers: { label: string; channel: string; color: string; }[];
}

interface UploadSlideMultilayerConfigResponse {
    success: boolean;
    message: string;
}

interface UpdateMCDRequest {
    id: number;
    body: {
        name?: string;
        description?: string;
        [key: string]: any;
    };
}

const PREFIX_URL = '/multi_layer';

export const mcdAPI = api.injectEndpoints({
    endpoints: (build) => ({
        getMCDFile: build.query<MCDFile, number>({
            query: (fileId) => ({ url: `${PREFIX_URL}/mcd/${fileId}` }),
            transformResponse: (response: {data: MCDFile, success: boolean}) => ({...response.data, _id: response.data.id}),
        }),
        deleteMCDFile: build.mutation<{success: boolean, message: string}, number>({
            query: (fileId) => ({ url: `${PREFIX_URL}/mcd/${fileId}/`, method: 'DELETE' }),
            transformResponse(baseQueryReturnValue, meta) {
                if (meta?.response?.status === 204) {
                    return {
                        success: true,
                        message: 'File deleted successfully'
                    };
                }
                return baseQueryReturnValue as {success: boolean, message: string};
            }
        }),
        updateSlideMultilayerConfig: build.mutation<UploadSlideMultilayerConfigResponse, UploadSlideMultilayerConfigRequest>({
            query: ({ slide, roi, layers }) => {
                const formData = new FormData();
                formData.append('slide', slide.toString());
                formData.append('roi', roi.toString());
                formData.append('layers', JSON.stringify(layers));

                return {
                    url: '/slide-multilayer-config/',
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        updateMCDFile: build.mutation<MCDFile, UpdateMCDRequest>({
            query: ({ id, body }) => ({
                url: `${PREFIX_URL}/mcd/${id}/`,
                method: 'PATCH',
                body,
            }),
            transformResponse: (response: {data: MCDFile, success: boolean}) => ({
                ...response.data,
                _id: response.data.id
            }),
        }),
    }),
});

export const {
    useGetMCDFileQuery,
    useLazyGetMCDFileQuery,  // Add this export
    useDeleteMCDFileMutation,
    useUpdateSlideMultilayerConfigMutation,
    useUpdateMCDFileMutation,
} = mcdAPI;

export const {
    endpoints: { getMCDFile },
} = mcdAPI;