import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ROOT_FOLDER_NAME, FILE_TYPES } from '../../../constants';
import { useLazyGetMCDFileQuery } from '../../../services/mcd';
import { useDeleteProjectMutation } from '../../../services/projects';
import {
  useDeleteSlideMutation,
  useGetDownloadUrlMutation,
  useGetSlideProcessingStatusMutation
} from '../../../services/slides';
import {
  selectBreadcrumbs,
  selectCurrentFolderView,
  selectFoldersAndFiles,
  setFoldersAndFiles
} from '../../../stores/slidebox/slideboxSlice';
import InProcessingDialog from '../InProcessingDialog';
import MultilayerFileDialog from "../MCDInProcessingDialog";
import ExplorerDetails from './components/ExplorerDetails';
import { FileAndFolderMove } from './components/FileAndFolderMove';
import ListView from './components/ListView';
import TableView, { SHOW_THUMBNAILS } from './components/TableView';

const FileExplorer = () => {
  const { state, dispatch } = useSlideboxContext();
  const theme = useTheme();
  const foldersAndFiles = useSelector( selectFoldersAndFiles );
  const navigate = useNavigate();
  const {
    _id: id,
    name: folderName,
    type: entityType
  } = useSelector( selectCurrentFolderView );
  const breadcrumbs = useSelector( selectBreadcrumbs );

  const headCells = useMemo( () => {
    const headers = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        sortable: true
      },
      {
        id: 'size',
        numeric: false,
        disablePadding: false,
        label: 'Size',
        sortable: true
      },
      {
        id: 'annotationCount',
        numeric: false,
        disablePadding: false,
        label: 'Annotations',
        sortable: true
      },
      {
        id: 'shared',
        numeric: true,
        disablePadding: false,
        label: 'Shared',
        sortable: true
      },
      {
        id: 'creadtedBy',
        numeric: false,
        disablePadding: false,
        label: 'Created By',
        sortable: true
      },
      {
        id: 'lastModifiedDate',
        numeric: false,
        disablePadding: false,
        label: 'Last Modified',
        sortable: true
      },
      {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: '',
        sortable: false
      }
    ];
    if ( entityType === FILE_TYPES.CASE ) {
      headers.splice( 4, 0, {
        id: 'zoomLevel',
        numeric: false,
        disablePadding: false,
        label: 'Metadata',
        sortable: true
      }, {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        sortable: true
      } );
      if ( SHOW_THUMBNAILS ) {
        headers.unshift( {
          id: 'thumbnail',
          numeric: false,
          disablePadding: false,
          label: 'Thumbnail',
          sortable: false
        } );
      }
    }
    return headers;
  }, [ entityType ] );

  const mdAndDown = useMediaQuery( theme.breakpoints.down( 'md' ) );

  const FileExplorerComponent = useMemo( () => {
    if ( mdAndDown ) return ListView;
    return TableView;
  }, [ mdAndDown ] );

  const rows = useMemo( () => {
    let data = [];
    if ( folderName === DEFAULT_ROOT_FOLDER_NAME ) {
      data = foldersAndFiles.filter( ( item ) => !item.parent );
    } else {
      data = foldersAndFiles.filter( ( item ) => item.parent === id );
      // Add MCD files if they exist in the current folder
      const mcdFiles = data.mcd_files || [];
      data = [
        ...data,
        ...mcdFiles.map( file => ( {
          ...file,
          type: FILE_TYPES.MCD,
          parent: id
        } ) )
      ];
    }
    return data.map( ( item ) => ( {
      ...item,
      lastModifiedDate: item.updatedAt || item.updated_at,
      assignedTo: '',
      avatar: '',
      status: item.status || ''
    } ) );
  }, [ foldersAndFiles, id, folderName ] );

  const [ openInProcessingDialog, setOpenInProcessingDialog ] = useState( false );
  const [ isMCDProcessDialogOpen, setMCDProcessDialogOpen ] = useState( false );
  const [ openDeleteDialog, setOpenDeleteDialog ] = useState( false );
  const [ openDialog, setOpenDialog ] = useState( false );
  const [ dialogTitle, setDialogTitle ] = useState( '' );
  const [ getSlideProcessingStatus ] = useGetSlideProcessingStatusMutation();
  const [ getMCDFile ] = useLazyGetMCDFileQuery();

  const isSelected = ( nodeId ) =>
    state.selectedRows.findIndex( ( r ) => r._id === nodeId ) !== -1;

  const reduxDispatch = useDispatch();

  const { handleSetCurrentView } = useSlideBox();

  const handleDoubleClick = async ( row ) => {
    if ( row.type === FILE_TYPES.FOLDER || row.type === FILE_TYPES.CASE ) {
      dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
      return handleSetCurrentView( row._id, row.parent );
    }
    if ( row.type === FILE_TYPES.MCD ) {
      try {
        const { data: mcdFile } = await getMCDFile( row._id );
        if ( !mcdFile?.slides?.length ) {
          enqueueSnackbar( 'No slides found in MCD file', { variant: 'error' } );
          return;
        }
        const firstSlideId = mcdFile.slides[ 0 ]._id;
        dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
        const { success, data: slideProcessingStatus } = await getSlideProcessingStatus( firstSlideId ).unwrap();
        if ( !success || slideProcessingStatus ) {
          return setOpenInProcessingDialog( true );
        }

        return navigate( `/slideViewer/mcd/${ firstSlideId }`, {
          replace: true,
          state: { mcdFile }
        } );
      } catch ( err ) {
        enqueueSnackbar( 'Failed to load MCD file data', { variant: 'error' } );
        return;
      }
    }
    try {
      const { success, data: slideProcessingStatus } =
        await getSlideProcessingStatus( row._id ).unwrap();
      if ( success ) {
        if (
          ( row.type === FILE_TYPES.FILE && !row.gcsFileName && !row.tilePath ) ||
          slideProcessingStatus
        ) {
          return setOpenInProcessingDialog( true );
        }
        dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
        return navigate( `/slideViewer/${ row._id }`, { replace: true } );
      }
      throw new Error( '' );
    } catch ( err ) {
      const errorMessage =
        err?.data?.error ||
        err?.data?.error?.message ||
        'Something went wrong !';
      enqueueSnackbar( errorMessage, {
        variant: 'error'
      } );
      setTimeout( () => navigate( `/slidebox` ), 3000 );
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog( false );
  };
  const [ deleteProject ] = useDeleteProjectMutation();
  const [ deleteSlide ] = useDeleteSlideMutation();

  const { enqueueSnackbar } = useSnackbar();

  const deleteFolderOrCase = async ( item ) => {
    return deleteProject( item._id ).unwrap();
  };

  const deleteFile = ( item ) => {
    return deleteSlide( item._id ).unwrap();
  };

  const handleConfirmDelete = async () => {
    try {
      handleCloseDeleteDialog();
      setDialogTitle( 'DELETING FILE' );
      setOpenDialog( true );

      const promises = state.selectedRows.map( ( row ) => {
        if ( row.type === FILE_TYPES.FILE ) return deleteFile( row );
        return deleteFolderOrCase( row );
      } );

      await Promise.all( promises );
      reduxDispatch(
        setFoldersAndFiles(
          foldersAndFiles.filter(
            ( x ) => state.selectedRows.findIndex( ( r ) => r._id === x._id ) === -1
          )
        )
      );

      enqueueSnackbar( `Delete successfully.`, {
        variant: 'success'
      } );
      dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
    } catch ( err ) {
      console.log( err );
      if ( err.data && typeof err.data.error === 'string' ) {
        enqueueSnackbar( err.data.error, { variant: 'error' } );
      } else {
        enqueueSnackbar( 'Something went wrong !', { variant: 'error' } );
      }
    } finally {
      setOpenDialog( false );
    }
  };

  const [ getDownloadUrl ] = useGetDownloadUrlMutation();
  const onDownloadFile = async () => {
    try {
      const response = await getDownloadUrl( state.selectedNodeId ).unwrap();
      const downloadUrl = response.data;
      let a = document.createElement( 'a' );
      a.href = downloadUrl;
      a.target = '_blank';
      a.download = folderName;
      a.click();
    } catch ( err ) {
      console.log( err );
      enqueueSnackbar( err.data.error, { variant: 'error' } );
    }
  };

  const handleBreadcrumbsClick = ( index ) => {
    dispatch( { type: 'SET_STATE', key: 'selectedRows', value: [] } );
    const folder = breadcrumbs[ index ];
    handleSetCurrentView( folder._id );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }}
      >
        <FileExplorerComponent
          rows={rows}
          headCells={headCells}
          breadcrumbs={breadcrumbs}
          isSelected={isSelected}
          handleDoubleClick={handleDoubleClick}
          setOpenDeleteDialog={setOpenDeleteDialog}
          handleBreadcrumbsClick={handleBreadcrumbsClick}
          onDownloadFile={onDownloadFile}
        />
      </Paper>

      {/* File And Folder Details App Drawer */}
      <ExplorerDetails open={state.openDetail} />
      <FileAndFolderMove
        open={state.openMoveFolder}
        setOpen={( value ) =>
          dispatch( { type: 'SET_STATE', key: 'openMoveFolder', value } )
        }
      />

      <InProcessingDialog
        open={openInProcessingDialog}
        handleClose={() => setOpenInProcessingDialog( false )}
      />
      <MultilayerFileDialog
        open={isMCDProcessDialogOpen}
        handleClose={() => setMCDProcessDialogOpen( false )}
      />
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">CONFIRM</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete {state.selectedRows.length} item(s) ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog( false )}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: '10px',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
          <DialogContentText id="alert-dialog-description">
            Please wait a moment...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
export default FileExplorer;
