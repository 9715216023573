import {
  FilterList as FilterListIcon,
  Search as SearchIcon,
  Pending as PendingIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDate } from 'utils/appHelpers';
import { CustomInput } from '../../../../components/Shared/Input';
import { CustomSelect } from '../../../../components/Shared/Select';
import CustomTable from '../../../../components/Table';
import {
  DATE_FILTER_VALUES,
  FILE_ICON,
  FILE_TYPES,
  ROLES
} from '../../../../constants';
import { useSearchMutation } from '../../../../services/projects';
import {
  isSlideboxLoading,
  selectCurrentFolderView,
  slideBoxFilters,
  slideBoxPage
} from '../../../../stores/slidebox/slideboxSlice';
import { getSize } from '../../../../utils/fileUtils';
import { filterRecords } from '../../../../utils/filterHelpers';
import FileProcessingDialog from '../../../SlideViewer/FileProcessingDialog';
import FileAndFolderActionMenu from './ActionMenu';
import EnhancedTableToolbar from './TableViewComponents/EnhancedTableToolbar';

export const SHOW_THUMBNAILS = false; //TODO Set to true when the thumbnails are ready on the backend

const FileAndFolderListTableView = ( {
  rows = [],
  setOpenShareDialog,
  setOpenDeleteDialog,
  headCells,
  isSelected,
  handleDoubleClick,
  breadcrumbs,
  handleBreadcrumbsClick
} ) => {
  const {
    user: { role }
  } = useAuth();
  const isMountedRef = useRef( false );
  const isSuperAdmin = useMemo( () => role === ROLES.SUPER_ADMIN, [ role ] );
  const isAdmin = useMemo( () => role === ROLES.ADMIN, [ role ] );
  const isUser = useMemo( () => role === ROLES.USER, [ role ] );
  const isCollaborator = useMemo( () => role === ROLES.COLLABORATOR, [ role ] );

  const { type: folderType } = useSelector( selectCurrentFolderView );
  const stateFilters = useSelector( slideBoxFilters );
  const getSlideboxPage = useSelector( slideBoxPage );
  const slideboxLoading = useSelector( isSlideboxLoading );

  const { state, dispatch, setViewComponent } = useSlideboxContext();

  const {
    handleSetFilters,
    handleSetSlideBoxPage,
    handleSetSlideBoxRowsPerPage
  } = useSlideBox();

  const [ showFilters, setShowFilters ] = useState(
    stateFilters.fileType || stateFilters.ownerEmail || stateFilters.updatedAt
  );

  const [ dataChange, setDataChange ] = useState( false );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ currentPage, setCurrentPage ] = useState( getSlideboxPage );
  const [ displayText, setDisplayText ] = useState( false );
  const [ filters, setFilters ] = useState( { ...stateFilters } );
  const [ records, setRecords ] = useState( rows );
  const [ searchProjects ] = useSearchMutation();
  const { palette } = useTheme();

  useEffect( () => {
    isMountedRef.current = true;
    setRecords( rows );
    return () => {
      isMountedRef.current = false;
    };
  }, [ rows ] );

  useEffect( () => {
    setIsLoading( true );
    setTimeout( () =>
      setIsLoading( false ), 1000
    );
  }, [] );

  const updateFilter = ( name, value ) => {
    setDataChange( true );
    setCurrentPage( 0 );
    handleSetSlideBoxPage( 0 );

    setTimeout( () => {
      setFilters( ( currentVal ) => {
        const newVal = { ...currentVal, [ name ]: value };
        handleSetFilters( newVal );
        setDataChange( false );
        return newVal;
      } );
    }, 0 );
  };

  const handleResetFilters = () => {
    setDataChange( true );
    setCurrentPage( 0 );
    handleSetSlideBoxPage( 0 );

    setFilters( ( currentVal ) => {
      const newVal = {
        keyword: '',
        fileType: '',
        ownerEmail: '',
        updatedAt: ''
      };
      handleSetFilters( newVal );
      setDataChange( false );
      return newVal;
    } );
  };

  const handleChangeKeyword = ( event ) => {
    setFilters( ( currentVal ) => {
      const newVal = { ...currentVal, keyword: event.target.value };
      return newVal;
    } );
  };

  const handleClearKeyword = () => {
    updateFilter( 'keyword', '' );
    setRecords( rows );
    setDisplayText( false );
  };

  const handleKeyPress = async ( event ) => {
    if ( event.key === 'Enter' ) {
      await handleSearchClick();
    }
  };

  // Filter handlers
  const handleInputFilterChange = ( e ) => {
    updateFilter( e.target.name, e.target.value );
  };

  const handleToggleFilters = () => {
    setShowFilters( !showFilters );
  };

  const ownerEmails = useMemo( () => {
    if ( !records.length ) return [];
    const emails = records.map( ( x ) => x?.createdBy?.email );
    return emails.filter( ( v, i, a ) => a.indexOf( v ) === i );
  }, [ records ] );

  const filteredRecords = useMemo(
    () => filterRecords( records, filters ),
    [ records, filters ]
  );

  const isUploadable = useMemo( () => {
    return folderType === FILE_TYPES.CASE;
  }, [ folderType ] );

  const handleSearchClick = async () => {
    if ( filters.keyword.length === 0 ) {
      handleClearKeyword();
    } else {
      try {
        setIsLoading( true );
        const response = await searchProjects( filters.keyword ).unwrap();
        setRecords( response.data );
        setDisplayText( true );
        handleSetFilters( filters );
        setIsLoading( false );
      } catch ( error ) {
        setRecords( [] );
        setDisplayText( false );
        setIsLoading( false );
      }
    }
  };

  const handleRowClick = ( row ) => {
    dispatch( {
      type: 'TOGGLE_SELECTED_ROW',
      value: row
    } );
  };

  const handleRowDoubleClick = ( row ) => {
    if ( state.selectedRows.length > 1 ) {
      dispatch( {
        type: 'SET_STATE',
        key: 'selectedRows',
        value: []
      } );
      return;
    } else if (
      row.type === FILE_TYPES.FILE &&
      !row.gcsFileName &&
      !row.tilePath &&
      row.isProcessing
    ) {
      return;
    } else {
      handleDoubleClick( row );
    }
  };

  return (
    <Box>
      {isLoading && <FileProcessingDialog open={isLoading} text="FETCHING PROJECTS" />}

      <Grid display="flex" sx={{ marginBottom: '12px' }}>
        <CustomInput
          style={{ flexGrow: '1' }}
          fullWidth={true}
          placeholder="Search projects..."
          inputProps={{
            style: {
              fontSize: '16px',
              padding: '7px 16px'
            }
          }}
          value={filters.keyword}
          onChange={handleChangeKeyword}
          onKeyPress={( e ) => handleKeyPress( e )}
          InputProps={{
            endAdornment: filters.keyword && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClearKeyword}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          sx={{
            alignSelf: 'center',
            marginLeft: 'auto',
            height: '100%',
            margin: '0px 40px',
            paddingLeft: '24px',
            paddingRight: '24px'
          }}
          variant="contained"
          component="label"
          disableElevation
          onClick={handleSearchClick}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
        <Button
          startIcon={<FilterListIcon />}
          sx={{
            alignSelf: 'center',
            marginLeft: 'auto',
            height: '100%',
            paddingLeft: '24px',
            paddingRight: '24px'
          }}
          variant="contained"
          component="label"
          disableElevation
          onClick={handleToggleFilters}
        >
          Filters
        </Button>
      </Grid>
      {showFilters && (
        <div style={{ marginBottom: '12px' }}>
          <Divider style={{ marginBottom: '12px' }} />
          <Grid container spacing={1.5} columns={25}>
            <Grid item xs={5}>
              <FormControl fullWidth={true}>
                <CustomSelect
                  fullWidth={true}
                  name="fileType"
                  sx={{
                    ' .MuiSelect-select': {
                      padding: '8px 46px 8px 10px!important',
                      fontSize: '16px!important'
                    }
                  }}
                  value={filters.fileType}
                  onChange={handleInputFilterChange}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={FILE_TYPES.FOLDER}>Folder</MenuItem>
                  <MenuItem value={FILE_TYPES.CASE}>Case</MenuItem>
                  <MenuItem value={FILE_TYPES.FILE}>Slide</MenuItem>
                  <MenuItem value={FILE_TYPES.MCD}>MCD</MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
            {ownerEmails.length > 0 && (
              <Grid item xs={9}>
                <FormControl fullWidth={true}>
                  <CustomSelect
                    fullWidth={true}
                    sx={{
                      ' .MuiSelect-select': {
                        padding: '8px 46px 8px 10px!important',
                        fontSize: '16px!important'
                      }
                    }}
                    name="ownerEmail"
                    value={filters.ownerEmail}
                    onChange={handleInputFilterChange}
                    displayEmpty
                  >
                    <MenuItem value="">All</MenuItem>
                    {ownerEmails.map( ( email, index ) => (
                      <MenuItem value={email} key={index}>
                        {email}
                      </MenuItem>
                    ) )}
                  </CustomSelect>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={7}>
              <FormControl fullWidth={true}>
                <CustomSelect
                  fullWidth={true}
                  sx={{
                    ' .MuiSelect-select': {
                      padding: '8px 46px 8px 10px!important',
                      fontSize: '16px!important'
                    }
                  }}
                  name="updatedAt"
                  value={filters.updatedAt}
                  onChange={handleInputFilterChange}
                  displayEmpty
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={DATE_FILTER_VALUES.Today}>
                    {DATE_FILTER_VALUES.Today}
                  </MenuItem>
                  <MenuItem value={DATE_FILTER_VALUES.Yesterday}>
                    {DATE_FILTER_VALUES.Yesterday}
                  </MenuItem>
                  <MenuItem value={DATE_FILTER_VALUES.PastWeek}>
                    {DATE_FILTER_VALUES.PastWeek}
                  </MenuItem>
                  <MenuItem value={DATE_FILTER_VALUES.PastMonth}>
                    {DATE_FILTER_VALUES.PastMonth}
                  </MenuItem>
                </CustomSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth={true}
                variant="contained"
                color="gray"
                disableElevation
                onClick={handleResetFilters}
                sx={{ height: '100%' }}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          justifyContent: 'space-between'
        }}
      >
        {filters.keyword && displayText ? (
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '18px',
              cursor: 'pointer'
            }}
            onClick={() => { }}
          >
            Searching {filters.keyword}
          </Typography>
        ) : (
          <Breadcrumbs maxItems={2} aria-label="breadcrumb">
            {breadcrumbs.map( ( folder, index ) => (
              <Typography
                sx={{
                  fontWeight: index === breadcrumbs.length - 1 ? '600' : '',
                  fontSize: '18px',
                  cursor: 'pointer'
                }}
                key={folder._id}
                onClick={() =>
                  index === breadcrumbs.length - 1
                    ? null
                    : handleBreadcrumbsClick( index )
                }
              >
                {folder.name}
              </Typography>
            ) )}
          </Breadcrumbs>
        )}
        <Box display={'inline-flex'}>
          <EnhancedTableToolbar
            onClickShareIcon={() => setOpenShareDialog( true )}
            onClickDeleteIcon={() => setOpenDeleteDialog( true )}
          />
          {( isUploadable && !isCollaborator ) && (
            <Button
              sx={{
                alignSelf: 'center',
                marginBottom: '12px',
                marginLeft: '12px',
                paddingLeft: '24px',
                paddingRight: '24px'
              }}
              disableElevation
              variant="contained"
              component="label"
              onClick={() => {
                setViewComponent( 'file_upload' );
              }}
            >
              Upload
            </Button>
          )}
        </Box>
      </Toolbar>
      <Divider />
      {!dataChange && (
        <CustomTable
          currentPage={currentPage}
          rows={filteredRecords}
          headCells={headCells}
          isLoading={slideboxLoading}
          emptyData={
            folderType === FILE_TYPES.FOLDER
              ? 'No projects found'
              : 'No slides found'
          }
          onPageChange={( page ) => {
            handleSetSlideBoxPage( page );
          }}
          onRowsPerPageChange={( rowsPerPage ) => {
            handleSetSlideBoxRowsPerPage( rowsPerPage );
          }}
        >
          {( head, items ) =>
            items.map( ( row ) => {
              const isItemSelected = isSelected( row._id );
              if ( !row.type ) row.type = 'file';

              const IconComponent = FILE_ICON[ row.type ] ?? null;
              return (
                <TableRow
                  sx={{ cursor: 'pointer' }}
                  hover
                  onClick={() => handleRowClick( row )}
                  onDoubleClick={() => handleRowDoubleClick( row )}
                  onKeyPress={() => handleRowDoubleClick( row )}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                >
                  {SHOW_THUMBNAILS && ( row.type === FILE_TYPES.FILE || row.type === FILE_TYPES.MCD ) && (
                    <TableCell align="center" sx={{ width: '100px' }}>
                      <img
                        src={row.thumbnail ?? '/assets/images/default-thumbnail.png'}
                        alt={row.name}
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                          backgroundColor: '#f5f5f5'
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: 'inline-grid',
                        gridAutoFlow: 'column',
                        gridTemplateColumns: 'max-content',
                        alignItems: 'center',
                        justifyContent: 'left',
                        minWidth: '200px',
                        marginTop: '6px'
                      }}
                    >
                      {IconComponent && <IconComponent sx={{ marginRight: '4px' }} />}
                      <span
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        title={row.name}
                      >
                        {row.name}
                      </span>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{getSize( row.size )}</TableCell>
                  <TableCell align="center">{row.annotationCount}</TableCell>
                  <TableCell align="center">{row.sharedEntities ? row.sharedEntities.length : '-'}</TableCell>
                  {folderType === FILE_TYPES.CASE && (
                    <TableCell align="center">
                      {row.lcmData && row.lcmData.Scan ? `${ row.lcmData.Scan.Objective }x` : '10x'}
                    </TableCell>
                  )}
                  {folderType === FILE_TYPES.CASE && (
                    <TableCell align="center">
                      {row.isProcessing ? (
                        <PendingIcon color="warning" titleAccess="Processing" />
                      ) : (
                        <CheckCircleIcon color="success" titleAccess="Ready" />
                      )}
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Box display="inline-flex" alignItems="center" title={row?.createdBy?.email}>
                      <Avatar
                        sx={{
                          marginRight: '6px',
                          width: '30px',
                          height: '30px',
                          backgroundColor: row?.createdBy?.avatarURL ? 'transparent' : palette.primary.main,
                          textTransform: 'uppercase'
                        }}
                        src={row?.createdBy?.avatarURL}
                      >
                        {!row?.createdBy?.avatarURL && row?.createdBy?.firstName?.[ 0 ]}
                      </Avatar>
                      {`${ row?.createdBy?.firstName } ${ row?.createdBy?.lastName }`}
                    </Box>
                  </TableCell>
                  <TableCell align="center">{formatDate( row.updatedAt, 'MMM DD, YYYY LT' )}</TableCell>
                  <TableCell align="center" onDoubleClick={( e ) => e.stopPropagation()} onClick={( e ) => e.stopPropagation()} onKeyPress={( e ) => e.stopPropagation()}>
                    {( isSuperAdmin || isAdmin || isUser ) && <FileAndFolderActionMenu row={row} rows={items} />}
                  </TableCell>
                </TableRow>
              );
            } )
          }
        </CustomTable>
      )}
    </Box>
  );
};

export default FileAndFolderListTableView;
