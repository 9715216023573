/**
 * @description the recogito widget displaying Annotation title on Shape Popover,
 * for reference: https://recogito.github.io/guides/editor-widgets/
 * @param annotation
 * @returns {HTMLDivElement}
 * @constructor
 */
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ReactDOM from 'react-dom';

export const AnnoAnalysisBtnWidget = ({ annotation, onClick }) => {
  const divElement = document.createElement('div');
  const deleteButton = document.querySelector(
    'button.r6o-btn.delete-annotation'
  );
  const analysisButton = document.querySelector(
    'button.r6o-btn.analysis-style'
  );

  // covers add and edit annotation flows
  // as we have to display analysis button only if delete button exists
  // that is the edit annotation mode
  // only add if analysis button is not already added
  if (deleteButton && !analysisButton) {
    const analysisBtn = document.createElement('button');
    analysisBtn.classList.add('r6o-btn');
    analysisBtn.classList.add('analysis');
    analysisBtn.classList.add('analysis-style');
    analysisBtn.setAttribute('title', 'Analysis Table');
    analysisBtn.onclick = onClick;
    deleteButton.insertAdjacentElement('afterend', analysisBtn);
    ReactDOM.render(<ShowChartIcon fontSize="small" />, analysisBtn);
  }

  // return empty element always
  // as we are appending analysis button next to the delete button
  return divElement;
};
