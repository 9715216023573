import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Container, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import BioMarkerTab from './BioMarkerTab';

const AnalysisGraphPopUp = ({ biomarkers, isFetching, slideId, groupId }) => {
  const theme = useTheme();

  return (
    <Container maxWidth="100%">
      {typeof biomarkers != 'undefined' ? (
        <TabContext value={'0'}>
          <TabPanel value="0" key="0">
            <BioMarkerTab biomarkers={biomarkers} slideId={slideId} group1Id={groupId} />
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {isFetching ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default AnalysisGraphPopUp;
