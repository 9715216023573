import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Container, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import BioMarkerGraphRender from './BioMarkerGraphRender';

const HEATMAP_KEY = 'HeatMap';
const PCA_PLOT_KEY = "PCA Plot";
const SCATTER_PLOT_KEY = "Scatter Plot";
const BACKEND_GRAPHS = [ HEATMAP_KEY, SCATTER_PLOT_KEY, PCA_PLOT_KEY ];
const EXCLUDED_KEYS = [ 'Accession', 'Description', 'Antibody', 'parsedName' ];

const BioMarkerTab = ( {
  biomarkers,
  compBiomarker,
  openedFor,
  compAnnoColor,
  slideId,
  group1Id,
  group2Id,
} ) => {
  const isBiomarkersStandard = biomarkers.dataType === 'standard';
  const theme = useTheme();

  const [ allBiomarkerKey, setAllBiomarkerKey ] = useState( {} );
  const [ compDataSetValue, setCompDataSetValue ] = useState( {} );
  const [ dataSetValue, setDataSetValue ] = useState( {} );
  const [ error, setError ] = useState( null );
  const [ heatMapHtmlContent, setHeatmapHtmlContent ] = useState( '' );
  const [ loading, setLoading ] = useState( true );
  const [ pcaHtmlContent, setPcaHtmlContent ] = useState( '' );
  const [ scatterPlotHtmlContent, setScatterPlotHtmlContent ] = useState( '' );
  const [ value, setValue ] = useState( '0' );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };
  // New useEffect hook to handle biomarker data processing
  useEffect( () => {
    if ( !biomarkers.biomarkerData?.length ) return;

    let keys = Object.keys( biomarkers.biomarkerData[ 0 ] ).filter(
      ( key ) => !EXCLUDED_KEYS.includes( key ) &&
        typeof biomarkers.biomarkerData[ 0 ][ key ] !== 'boolean'
    );

    if ( isBiomarkersStandard ) {
      if ( openedFor === 'comp' ) {
        keys.unshift( SCATTER_PLOT_KEY, HEATMAP_KEY );
      }
      keys.unshift( PCA_PLOT_KEY );
    }

    setAllBiomarkerKey( ( prev ) => ( {
      ...prev,
      [ biomarkers.cap ]: keys,
    } ) );

    const newDataSetValue = {};
    const newCompDataSetValue = {};

    keys.forEach( ( key ) => {
      newDataSetValue[ key ] = [];
      newCompDataSetValue[ key ] = [];
    } );

    biomarkers.biomarkerData.forEach( biomarker => {
      const { Accession, Description, Antibody, ...otherFields } = biomarker;

      keys.forEach( key => {
        const formattedData = {
          Description: Accession ? Description : Antibody || '',
        };

        if ( Accession ) {
          formattedData.Accession = Accession;
        }

        formattedData[ key ] = otherFields[ key ];
        newDataSetValue[ key ].push( formattedData );
      } );
    } );

    if ( openedFor === 'comp' && compBiomarker && compBiomarker.biomarkerData ) {
      compBiomarker.biomarkerData.forEach( ( bData ) => {
        const { Accession, Description, Antibody, ...otherFields } = bData;

        keys.forEach( key => {
          const formattedData = {
            Description: Accession ? Description : Antibody || '',
          };

          if ( Accession ) {
            formattedData.Accession = Accession;
          }

          formattedData[ key ] = otherFields[ key ];
          newCompDataSetValue[ key ].push( formattedData );
        } );
      } );
    }

    setDataSetValue( newDataSetValue );
    setCompDataSetValue( newCompDataSetValue );
  }, [
    biomarkers,
    compBiomarker,
    openedFor,
    slideId,
    group1Id,
    group2Id,
  ] );

  /**
 * useEffect hook to fetch heatmap and scatter plot HTML content based on slideId, group1Id, and group2Id.
 * 
 * This hook triggers fetch operations to retrieve the heatmap and scatter plot HTML content
 * whenever the slideId, group1Id, or group2Id dependencies change. The fetched HTML content
 * is then set in the state using setHeatmapHtmlContent and setScatterPlotHtmlContent respectively.
 * If an error occurs during the fetch operations, the error message is set in the state using setError.
 * The loading state is managed using setLoading.
 * 
 * @param {string} slideId - The ID of the slide.
 * @param {string} group1Id - The ID of the first group.
 * @param {string} group2Id - The ID of the second group.
 */
  useEffect( () => {
    if ( !isBiomarkersStandard ) return;

    const fetchHeatmapHtml = async () => {
      try {
        const response = await fetch(
          `${ process.env.REACT_APP_BACKEND_API }api/v1/comparisons/heatmap?slideId=${ slideId }&group1Id=${ group1Id }&group2Id=${ group2Id }`
        );
        if ( !response.ok ) {
          throw new Error( 'Failed to fetch HTML content' );
        }
        const htmlText = await response.text();
        setHeatmapHtmlContent( htmlText );
        setLoading( false );
      } catch ( err ) {
        setError( err.message );
        setLoading( false );
      }
    };

    const fetchScatterPlotHtml = async () => {
      try {
        const response = await fetch(
          `${ process.env.REACT_APP_BACKEND_API }api/v1/comparisons/scatterPlot?slideId=${ slideId }&group1Id=${ group1Id }&group2Id=${ group2Id }&columnName=Coverage%2520%5B%25%5D`
        );
        if ( !response.ok ) {
          throw new Error( 'Failed to fetch HTML content' );
        }
        const htmlText = await response.text();
        setScatterPlotHtmlContent( htmlText );
        setLoading( false );
      } catch ( err ) {
        setError( err.message );
        setLoading( false );
      }
    };

    if ( slideId && group1Id && group2Id ) {
      fetchScatterPlotHtml();
      fetchHeatmapHtml();
    }
  }, [ slideId, group1Id, group2Id, isBiomarkersStandard ] );

  /**
   * useEffect hook to fetch PCA plot HTML content based on slideId and group1Id.
   * 
   * This hook triggers a fetch operation to retrieve the PCA plot HTML content
   * whenever the slideId or group1Id dependencies change. The fetched HTML content
   * is then set in the state using setPcaHtmlContent. If an error occurs during the
   * fetch operation, the error message is set in the state using setError.
   * 
   * @param {string} slideId - The ID of the slide.
   * @param {string} group1Id - The ID of the first group.
   */
  useEffect( () => {
    if ( !isBiomarkersStandard ) return;
    if ( slideId && group1Id ) {
      const fetchHtml = async () => {
        try {
          const response = await fetch(
            `${ process.env.REACT_APP_BACKEND_API }api/v1/visualizations/pcaPlot?slideId=${ slideId }&groupId=${ group1Id }`
          );
          if ( !response.ok ) {
            throw new Error( 'Failed to fetch HTML content' );
          }
          const htmlText = await response.text();
          setPcaHtmlContent( htmlText );
          setLoading( false );
        } catch ( err ) {
          setError( err.message );
          setLoading( false );
        }
      };

      fetchHtml();
    }
  }, [ slideId, group1Id, isBiomarkersStandard ] );

  const BackendGraphIframe = ( { loading, error, content, title, height = '700px' } ) => (
    loading ? <p>Loading HTML content...</p>
      : error ? <p>Error loading content: {error}</p>
        : <iframe
          srcDoc={content}
          style={{ width: '110%', height, border: 'none' }}
          title={title}
        />
  );

  const biomarkerKeys = allBiomarkerKey[ biomarkers.cap ] || [];
  return (
    <Container maxWidth="100%" style={{ overflowX: 'scroll !important' }}>
      {!biomarkers ? (
        <Box sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }} mt={1}>
          {biomarkers ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      ) : (
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            {biomarkerKeys.map( ( key, index ) => (
              <Tab label={key} value={index.toString()} key={index} />
            ) )}
          </TabList>
          {biomarkerKeys.map( ( key, index ) => (
            <TabPanel value={index.toString()} key={index}>
              {/* Regular biomarker data */}
              {!BACKEND_GRAPHS.includes( key ) && (
                <BioMarkerGraphRender
                  biomarkerData={dataSetValue[ key ]}
                  biomarkerName={key}
                  compBiomarkerData={compDataSetValue[ key ]}
                  compAnnoColor={compAnnoColor}
                />
              )}

              {/* PCA Plot */}
              {key === PCA_PLOT_KEY && slideId && group1Id && (
                <BackendGraphIframe
                  loading={loading}
                  error={error}
                  content={pcaHtmlContent}
                  title="PCA Plot Graph"
                />
              )}

              {/* Heatmap */}
              {key === HEATMAP_KEY && slideId && group1Id && group2Id && (
                <BackendGraphIframe
                  loading={loading}
                  error={error}
                  content={heatMapHtmlContent}
                  title="HeatMap Graph"
                />
              )}

              {/* Scatter Plot */}
              {key === SCATTER_PLOT_KEY && slideId && group1Id && group2Id && (
                <BackendGraphIframe
                  loading={loading}
                  error={error}
                  content={scatterPlotHtmlContent}
                  title="Scatter Plot Graph"
                />
              )}
            </TabPanel>
          ) )}
        </TabContext>
      )}
    </Container>
  );
};

export default BioMarkerTab;