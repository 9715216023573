import { Container } from '@mui/material';
import * as Plot from '@observablehq/plot';
import { useContext, useEffect, useRef } from 'react';
import { AnnotationContext } from '.';

export interface Datum {
    text: string;
    value: number;
}

interface BioMarkerDataStringGraphProps {
    wordFrequency: Datum[];
    compWordFrequency?: Datum[];
    compAnnoColor?: string;
}

const BioMarkerDataStringGraph = ({ wordFrequency, compWordFrequency, compAnnoColor }: BioMarkerDataStringGraphProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const color = useContext(AnnotationContext) || "#0073e6";

    useEffect(() => {
        if (!containerRef.current || !wordFrequency.length) return;

        const plot = Plot.plot({
            height: 500,
            marginBottom: 150,
            marginLeft: 100,
            y: {
                grid: true,
                label: "Frequency"
            },
            x: {
                label: null,
                tickRotate: -45  // Rotate labels for better readability
            },
            marks: [
                Plot.barY(wordFrequency, {
                    x: "text",
                    y: "value",
                    sort: { x: "-y" },
                    fill: color
                }),
                compWordFrequency && Plot.barY(compWordFrequency, {
                    x: "text",
                    y: "value",
                    sort: { x: "-y" },
                    fill: compAnnoColor || "#666666"
                }),
                Plot.ruleY([0])
            ].filter(Boolean)
        });

        containerRef.current.append(plot);
        return () => plot.remove();
    }, [wordFrequency, compWordFrequency, color, compAnnoColor]);

    return (
        <Container sx={ { overflowX: 'scroll !important', maxWidth: '100%' } }>
            <div ref={ containerRef } />
        </Container>
    );
};

export default BioMarkerDataStringGraph;