import { createContext, useEffect, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import UploadIcon from '@mui/icons-material/Upload';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';

import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';
import OpenSeaDragon from 'openseadragon';
import 'openseadragon-filtering';
import axios from '../../../services/axios';

import DescSec from 'components/DescSec';
import DrawToolbar from 'components/DrawToolbar';
import ToolButton from 'components/ToolButton';

import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Alert from 'components/Alert';
import InfoAlert from 'components/Alert/InfoAlert';
import RubberbandEllipseTool from 'components/DrawTool/Ellipse/RubberbandEllipseTool';
import RubberbandFreehandTool from 'components/DrawTool/Freehand/RubberbandFreehandTool';
import RubberbandRectangleTool from 'components/DrawTool/Rectangle/RubberbandRectangleTool';
import {
  AnalysisGraphBtnWidget,
  AnalysisGraphCompBtnWidget,
  AnnoAnalysisBtnWidget,
  AnnoAutocompleteWidget,
  AnnoFromWidget,
  AnnoInputWidget
} from 'components/Widgets';
import { ZoomSlider } from 'components/ZoomSlider';
import { SHAPE_TYPES } from 'constants';
import CopyButton from 'features/Slidebox/FileExplorer/components/CopyButton';
import MoveButton from 'features/Slidebox/FileExplorer/components/MoveButton';
import RepaintButton from 'features/Slidebox/FileExplorer/components/RepaintButton';
import SaveButton from 'features/Slidebox/FileExplorer/components/SaveButton';
import ShareButton from 'features/Slidebox/FileExplorer/components/ShareButton';
import useAnnotationSocket from 'hooks/useAnnotationSocket';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import useToggleState from 'hooks/useToggleState';
import { get, omit } from 'lodash';
import moment from 'moment';
import { MuiColorInput } from 'mui-color-input';
import { useSnackbar } from 'notistack';
import {
  useCreateAnnotationMutation,
  useDeleteAnnotationMutation,
  useEditAnnotationMutation
} from 'services/annotation';
import {
  useGetBiomarkerByAnnotationIdMutation
} from 'services/biomarker';
import { useGetMCDFileQuery, useUpdateSlideMultilayerConfigMutation } from 'services/mcd';
import { useGetProjectDetailsMutation } from 'services/projects';
import {
  useExportAnnotationsMutation,
  useGetFileByIdMutation,
  useLazyGetAllSlideAnnotationsQuery
} from 'services/slides';
import { useSaveSettingsMutation } from 'services/slideSettings';
import { MCDLayerColor, MCDLayerColorMapToRGB } from "types/MCDTypes";
import { convertArray } from 'utils/convertArray';
import {
  addStyleToAnnotation,
  addStyleToAnnotationAndPointer,
  convertSvgToSelector,
  initSelectedAnno,
  removeStyleFromAnnotation,
  unSelectedAnno
} from 'utils/shape.helper';
import { useAuth } from '../../../hooks/useAuth';
import { selectOrganization } from '../../../stores/auth/authSlice';
import SlideManager from '../SlideManager/SlideManager';
import SlideRightPanel from '../SlideRightPanel/SlideRightPanel';
import AnalysisGraphPopUp from './AnalysisGraphPopUp';
import AnalysisGraphPopUpWithCompare from './AnalysisGraphPopUpWithCompare';
import AnalysisPopUp from './AnalysisPopUp';
import MCDAreaDataPopUp from './MCDAreaDataPopUp';
import { useCanvasEvent } from './useAnnotationEvent';


export const AnnotationContext = createContext();

const URL_STORAGE = process.env.REACT_APP_GOOGLE_CLOUD_STORAGE + `/${ process.env.REACT_APP_GOOGLE_CLOUD_STORAGE_BUCKET }/uploads/`;
const MCD_STORAGE_KEYS = {
  SLIDES: 'mcd-slides',
  ROIS: 'mcd-rois',
  LAYERS: 'mcd-layers'
};
const MAX_RERENDER_COUNT = 3;
const GRAY_COLOR_PERCENTAGE_LIMIT = 0.9;

const saveMCDState = ( slideId, type, data ) => {
  let dataSelected = data
    .filter( item => item.selected && ( item.id ?? item._id ) !== undefined )
    .map( item => item.id ?? item._id );
  if ( type === MCD_STORAGE_KEYS.LAYERS ) {
    dataSelected = data
      .filter( item => item.selected && ( item.id ?? item._id ) !== undefined )
      .map( d => ( { id: d.id, color: d.color ?? MCDLayerColor.BLACK } ) );
  }
  try {
    localStorage.setItem( `${ slideId }-${ type }`, JSON.stringify( dataSelected ) );
  } catch ( error ) {
    console.error( 'Error saving MCD state to localStorage:', error );
  }
};
/**
 * Retrieves MCD (Multi-Channel Data) state from localStorage for a given slide ID
 * @param {string} slideId - The unique identifier of the slide
 * @returns {Object|null} An object containing MCDSlides, MCDROIs, and MCDLayers arrays from localStorage,
 *                        or null if slideId is not provided or if there's an error reading from localStorage
 * @property {Array} MCDSlides - Array of stored MCD slides
 * @property {Array} MCDROIs - Array of stored MCD regions of interest
 * @property {Array} MCDLayers - Array of stored MCD layers
 * @throws {Error} If there's an error parsing JSON from localStorage (caught internally)
 */
const getStoredMCDState = ( slideId ) => {
  if ( !slideId ) return null;

  try {
    return {
      MCDSlides: JSON.parse( localStorage.getItem( `${ slideId }-${ MCD_STORAGE_KEYS.SLIDES }` ) ) || [],
      MCDROIs: JSON.parse( localStorage.getItem( `${ slideId }-${ MCD_STORAGE_KEYS.ROIS }` ) ) || [],
      MCDLayers: JSON.parse( localStorage.getItem( `${ slideId }-${ MCD_STORAGE_KEYS.LAYERS }` ) ) || []
    };
  } catch ( error ) {
    console.error( 'Error reading MCD state from localStorage:', error );
    return null;
  }
};

const reducer = ( state, action ) => {
  switch ( action.type ) {
    case 'EXPORT_ANNOTATIONS': {
      return {
        ...state,
        exportAnnotationCount: state.exportAnnotationCount + 1
      };
    }

    case 'ADD_ANNOTATIONS': {
      const annotationsMap = new Map( state.annotations.map( item => [ item._id, item ] ) );
      const updatedAnnotations = new Map( annotationsMap );

      action.payload.forEach( newItem => {
        const existingItem = annotationsMap.get( newItem._id );

        if ( existingItem && existingItem.stroke !== newItem.stroke ) {
          annotationsMap.forEach( ( annotation, id ) => {
            if ( annotation.stroke === existingItem.stroke ) {
              updatedAnnotations.set( id, { ...annotation, stroke: newItem.stroke } );
            }
          } );
        }

        updatedAnnotations.set( newItem._id, {
          ...( existingItem || {} ),
          ...newItem
        } );
      } );

      return {
        ...state,
        annotations: Array.from( updatedAnnotations.values() )
      };
    }
    case 'UPDATE_ANNOTATION': {
      // update a single annotation item
      const newAnnotations = state.annotations.map( ( item ) => {
        if ( action.payload._id === item._id ) {
          return { ...item, ...action.payload };
        }
        else if ( action.payload._refreshGroup && action.payload.group === item.group )
          return { ...item, stroke: action.payload.stroke }; // Update stroke for matching group

        return item; // Return item unmodified if neither condition is met
      } );
      return { ...state, annotations: [ ...newAnnotations ] };
    }
    case 'DELETE_ANNOTATION': {
      const newAnnotations = [ ...state.annotations ];
      const index = state.annotations.findIndex(
        ( item ) => item.id === action.payload
      );

      const deletedAnnotation = newAnnotations[ index ];
      if ( index !== -1 ) {
        newAnnotations.splice( index, 1 );
      }

      if ( deletedAnnotation ) {
        state.anno.removeAnnotation( deletedAnnotation.id );
      }

      return {
        ...state,
        annotations: [ ...newAnnotations ]
      };
    }
    case 'SET_STATE': {
      return { ...state, [ action.key ]: action.value };
    }
    case 'UPDATE_GROUP_CHANGE': {
      return { ...state, [ action.key ]: state.selectedGroupChange + 1 };
    }
    case 'UPDATE_SELECTED_ANNO': {
      return { ...state, [ action.key ]: action.value };
    }
    case 'UPDATE_GROUP': {
      const strokeColors = new Set( action.payload.map( item => item.Color ) );

      const newAnnotations = state.annotations.filter( annotation =>
        strokeColors.has( annotation.stroke )
      );

      return {
        ...state,
        annotations: newAnnotations,
        slide: {
          ...state.slide,
          groups: action.payload
        }
      };
    }
    case 'RELOAD_ANNOTATIONS_TAG': {
      const slideTagIds = state.slide.tags.map( ( tag ) => {
        return tag.id.toString();
      } );
      const newAnnotations = state.annotations
        ? state.annotations.map( ( item ) => {
          const tags = ( item.tags || [] ).filter( ( tag ) => {
            if ( typeof tag === 'object' && tag.id ) {
              return slideTagIds.includes( tag.id.toString() );
            }
            return slideTagIds.includes( tag.toString() );
          } );
          return {
            ...item,
            tags
          };
        } )
        : [];
      return {
        ...state,
        annotations: [ ...newAnnotations ]
      };
    }
    case 'RELOAD_ANNOTATIONS_CASE_IDENTIFIER': {
      const slideCaseIdentifiers = state.slide.caseIdentifiers.map( ( ci ) => {
        return ci.id.toString();
      } );
      const newAnnotations = state.annotations
        ? state.annotations.map( ( item ) => {
          const caseIdentifiers = ( item.caseIdentifiers || [] ).filter(
            ( ci ) => {
              if ( typeof ci === 'object' && ci.id ) {
                return slideCaseIdentifiers.includes( ci.id.toString() );
              }
              return slideCaseIdentifiers.includes( ci.toString() );
            }
          );
          return {
            ...item,
            caseIdentifiers
          };
        } )
        : [];
      return {
        ...state,
        annotations: [ ...newAnnotations ]
      };
    }
    case 'RELOAD_BIOMARKERS': {
      return {
        ...state,
        biomarkers: action.payload
      };
    }
    case 'SET_MCD_LAYERS':
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.LAYERS, action.payload );
      return { ...state, MCDLayers: action.payload };
    case 'TOGGLE_MCD_LAYER_SELECT':
      const updatedMCDLayers = state.MCDLayers.map( ( layer, idx ) => idx === action.payload ? { ...layer, selected: !layer.selected } : layer
      );
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.LAYERS, updatedMCDLayers );
      return {
        ...state,
        rerenderCount: 0,
        MCDLayers: updatedMCDLayers
      };

    case 'SELECT_ALL_MCD_LAYERS':
      return { ...state, MCDLayers: state.MCDLayers.map( layer => ( { ...layer, selected: true } ) ) };

    case 'DESELECT_ALL_MCD_LAYERS':
      return { ...state, MCDLayers: state.MCDLayers.map( ( layer ) => ( { ...layer, selected: false } ) ) };
    case 'UPDATE_MCD_LAYER_COLOR':
      const { index, color } = action.payload;
      const updatedLayers = state.MCDLayers.map( ( option, i ) =>
        i === index ? { ...option, color } : option
      );
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.LAYERS, updatedLayers );
      return {
        ...state,
        MCDLayers: updatedLayers,
        rerenderCount: 0
      };
    case 'SET_MCD_ROIS':
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.ROIS, action.payload );
      return { ...state, MCDROIs: action.payload };
    case 'SELECT_MCD_ROI':
      const roiDescription = action.payload;
      if ( !roiDescription ) return state;
      const updatedROIs = state.MCDROIs.map( ( roi ) =>
        roi.description === roiDescription ? { ...roi, selected: true } : { ...roi, selected: false }
      );
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.ROIS, updatedROIs );
      return { ...state, MCDROIs: updatedROIs };

    case 'SET_MCD_SLIDES':
      let MCDSlidesData = action.payload || [];
      if ( MCDSlidesData.some( slide => slide.selected === undefined ) ) {
        MCDSlidesData = MCDSlidesData.map( slide => ( { ...slide, selected: false } ) );
      }
      if ( MCDSlidesData.length > 0 && MCDSlidesData.every( slide => !slide?.selected ) ) {
        MCDSlidesData[ 0 ] = { ...MCDSlidesData[ 0 ], selected: true };
      }
      return { ...state, MCDSlides: MCDSlidesData };

    case 'SELECT_MCD_SLIDE':
      const slideName = action.payload;

      const updatedSlides = state.MCDSlides.map( ( slide ) =>
        slide.name === slideName ? { ...slide, selected: true } : { ...slide, selected: false }
      );
      saveMCDState( state.mcd._id, MCD_STORAGE_KEYS.SLIDES, updatedSlides );
      return { ...state, MCDSlides: updatedSlides };

    case 'REMOVE_MCD_LAYER':
      return {
        ...state,
        MCDLayers: state.MCDLayers.filter( ( _, index ) => index !== action.payload )
      };

    case 'FILTER_ANNOTATIONS_BY_ROI': {
      const selectedROIId = action.payload;
      const filteredAnnotations = state.annotations.filter( annotation =>
        annotation.acquisition === selectedROIId
      );
      state.anno?.setAnnotations( filteredAnnotations );

      return {
        ...state,
        filteredAnnotations,
        currentROI: selectedROIId
      };
    };

    case 'INCREASE_RERENDER_COUNT':
      return { ...state, rerenderCount: state.rerenderCount + 1 };

    default:
      return state;
  }
};

const colorMapCache = new Map();

const ImageViewer = ( {
  tileSources,
  slide,
  annotationsData,
  refetchAnnotations,
  isMCDFile
} ) => {
  const [ getProjectDetails ] = useGetProjectDetailsMutation();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { user } = useAuth();
  const analysisFileAccept = '.xlsx,.csv';
  const analysisFileRef = useRef( null );
  const currentColor = useRef( slide.settings?.color || '#000000' );
  const inputAccept = '.xml';
  const inputFileRef = useRef( null );
  const organization = useSelector( selectOrganization );
  const projectId = slide.project;
  const tilesMapRef = useRef( new Map() );
  OpenSeaDragon.DEFAULT_SETTINGS.timeout = 600000;
  OpenSeaDragon.requestAnimationFrame = function ( callback ) {
    setTimeout( callback, 1 );
  };

  const initialState = {
    anno: null,
    annotations: [],
    annotationStyles: {},
    biomarkers: {},
    color: slide.settings?.color || '#000000',
    exportAnnotationCount: 0,
    isOverwrite: false,
    MCDLayers: [],
    MCDROIs: [],
    MCDSlides: [],
    rerenderCount: 0,
    selectedAnno: {},
    selectedGroupChange: 0,
    slide: slide ?? {},
    tool: '',
    uploadFile: null,
    viewer: null,
    project: null,
  };

  const [ state, dispatch ] = useReducer( reducer, initialState );
  const { emitAnnotationUpserted, emitAnnotationDeleted } = useAnnotationSocket(
    isMCDFile ? null : slide._id,
    dispatch,
    state.anno
  );
  const [ deleteAnno, showAnnoDeleteAlert, hideAnnoDeleteAlert ] =
    useToggleState( null );

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [ deleteAnnotation ] = useDeleteAnnotationMutation();
  const [ createAnnotation ] = useCreateAnnotationMutation();
  const [ editAnnotation ] = useEditAnnotationMutation();
  const [ exportAnnotations ] = useExportAnnotationsMutation();
  const [ getBiomarkerByAnnotationId, { isLoading: isReloadBiomarkers } ] = useGetBiomarkerByAnnotationIdMutation();
  const [ getFileById ] = useGetFileByIdMutation();
  const [ saveSettings ] = useSaveSettingsMutation();
  const [ triggerAnnotationsQuery ] = useLazyGetAllSlideAnnotationsQuery();

  const { data: mcdFileData } = useGetMCDFileQuery( slide._id, {
    skip: !isMCDFile
  } );
  const [ updateSlideMultilayerConfig ] = useUpdateSlideMultilayerConfigMutation();
  // const [ uploadSlideToProject ] = useUploadSlideToProjectMutation();

  //#region USE STATES
  const [ , setMCDSlides ] = useState( [] );
  const [ , setProteinSelected ] = useState( "" );
  const [ analysisFile, setAnalysisFile ] = useState( null );
  const [ backdrop, setBackdrop ] = useState( false );
  const [ compareAnno, setCompareAnno ] = useState( {} );
  const [ currentAnnotation, setCurrentAnnotation ] = useState( null );
  const [ currentTool, setCurrentTool ] = useState( '' );
  const [ group1Id, setGroup1Id ] = useState( -1 );
  const [ group2Id, setGroup2Id ] = useState( -1 );
  const [ isLoadingProject, setIsLoadingProject ] = useState( true );
  const [ isSelectedAnnotation, setIsSelectedAnnotation ] = useState( false );
  const [ maxMinZoomLevel, setMaxMinZoomLevel ] = useState( { min: 0.5, max: 12 } );
  const [ MCDSlideSelectedID, setMCDSlideSelectedID ] = useState( '' );
  const [ mulSelectMode, setMulSelectMode ] = useState( false );
  const [ open, setOpen ] = useState( true );
  const [ openAnalysisGraphCompModal, setOpenAnalysisGraphCompModal ] = useState( false );
  const [ projectData, setProjectData ] = useState( null );
  const [ openAnalysisGraphCompSelectionModal, setOpenAnalysisGraphCompSelectionModal ] = useState( false );
  const [ openAnalysisGraphModal, setOpenAnalysisGraphModal ] = useState( false );
  const [ openAnalysisModal, setOpenAnalysisModal ] = useState( false );
  const [ openMCDAreaData, setOpenMCDAreaData ] = useState( false );
  const [ overwriteAnalysisFileAlert, setOverwriteAnalysisFileAlert ] = useState( false );
  const [ overwriteAnno, setOverwriteAnno ] = useState( false );
  const [ panMode, setPanMode ] = useState( true );
  const [ selectAnnotation, setSelectAnnotation ] = useState( [] );
  const [ slideRightPanelOpen, setSlideRightPanelOpen ] = useState( false );
  const [ uploadAnnoConfirm, setUploadAnnoConfirm ] = useState( false );
  const [ uploadingAnnotations, setUploadingAnnotations ] = useState( false );
  const [ uploadingFile, setUploadingFile ] = useState( false );
  const [ zoomImageRatio, setZoomImageRatio ] = useState( 0 );
  const [ zoomLevel, setZoomLevel ] = useState( 1 );
  //#endregion

  //#region Handlers

  /**
   * Captures and saves the current OpenSeaDragon viewer state as a PNG image, uploading it to the server.
   * 
   * This function performs the following operations:
   * 1. Captures the current canvas state from the OpenSeaDragon viewer
   * 2. Converts it to a PNG blob
   * 3. Creates a file with timestamp in name
   * 4. Uploads to server with associated metadata
   * 5. Updates slide multilayer configuration
   * 
   * @async
   * @function handleSaveImage
   * @throws {Error} When image upload or save fails
   * @returns {Promise<void>}
   * 
   * @requires OpenSeaDragon
   * @requires axios
   * @requires FormData
   * 
   * @example
   * await handleSaveImage();
   */
  const handleSaveImage = async () => {
    if ( !state.viewer ) return;
    setBackdrop( true );

    // Store current viewport settings
    const currentZoom = state.viewer.viewport.getZoom();
    const currentCenter = state.viewer.viewport.getCenter();

    // Zoom out to fit
    state.viewer.viewport.goHome();

    // Wait for the zoom animation to complete
    await new Promise( resolve => {
      state.viewer.addOnceHandler( 'animation-finish', () => {
        resolve();
      } );
    } );

    // Create image from OpenSeaDragon viewer
    const canvas = state.viewer.drawer.canvas;
    canvas.toBlob( async ( blob ) => {
      if ( blob ) {
        try {
          const selectedRoiId = state.MCDROIs.find( roi => roi.selected )?.id ?? null;
          const selectedSlide = state.MCDSlides.find( slide => slide.selected );

          if ( !selectedRoiId || !selectedSlide ) {
            enqueueSnackbar( 'Please select a region of interest or slide', { variant: 'error' } );
            return;
          }

          const timestamp = new Date().toISOString().replace( /[:.]/g, '-' );
          const ROIName = state.MCDROIs.find( roi => roi.selected )?.description ?? 'ROI';
          const slideName = `Saved_Slide-${ selectedSlide.name }-${ ROIName }-${ timestamp }`;
          const imageFileName = `${ slideName || 'slide' }_${ timestamp }_export.png`;
          const file = new File( [ blob ], imageFileName, { type: 'image/png' } );
          // const projectName = projectData?.name ?? '';

          const endpoint = '/slides/uploadSlideToProject';
          const formData = new FormData();
          formData.append( 'file', file );
          formData.append( 'slideName', slideName );
          formData.append( 'createdByEmail', user.email );
          formData.append( 'projectId', projectData._id );

          const headers = {
            'Content-Type': 'multipart/form-data'
          };

          const res = await axios.post( endpoint, formData, { headers, } );
          if ( !res.data.success ) {
            enqueueSnackbar( 'Image upload failed', { variant: 'error' } );
            return;
          }
          const layerModificationInfo = {
            slide: selectedSlide._id,
            roi: selectedRoiId,
            layers: state.MCDLayers
              .filter( layer => layer.selected )
              .map( layer => ( {
                label: layer.label,
                channel: layer.channel,
                color: layer.color ?? 'white',
              } ) ),
          };

          await updateSlideMultilayerConfig( layerModificationInfo ).unwrap();
          //TODO Cloning the visible annotations to the new slide is encountering issues related to the positioning and size of the annotations on the new slide. We need to investigate this further.
          // const { slide } = res.data.data;
          // if ( slide._id ) {
          //   state.filteredAnnotations.forEach( async ( annotation ) => {
          //     const newAnnotation = {
          //       ...annotation,
          //       slide: slide._id,
          //       createdBy: user,
          //     };
          //     await createAnnotation( newAnnotation );
          //   } );
          // }
          enqueueSnackbar( `Image uploaded successfully with name: ${ slideName }`, { variant: 'success', autoHideDuration: 10000 } );

          // After saving, restore previous view
          state.viewer.viewport.zoomTo( currentZoom );
          state.viewer.viewport.panTo( currentCenter );

        } catch ( error ) {
          console.error( 'Image save error:', error );
          enqueueSnackbar( 'Image save failed: ' + error?.data?.error ?? '', { variant: 'error' } );

          // Restore view even if save fails
          state.viewer.viewport.zoomTo( currentZoom );
          state.viewer.viewport.panTo( currentCenter );
        }
        finally {
          setBackdrop( false );
        }
      }
    }, 'image/png' );
  };

  const handleRepaint = () => {
    if ( !isMCDFile || !state.viewer || state.rerenderCount >= MAX_RERENDER_COUNT ) return;

    state.viewer.world.removeAll();
    tilesMapRef.current.clear();

    dispatch( {
      type: 'SET_MCD_LAYERS',
      payload: [ ...state.MCDLayers ]
    } );
    dispatch( { type: 'INCREASE_RERENDER_COUNT', } );
  };

  const handleZoomChange = ( newValue, isCenter ) => {
    if ( newValue > maxMinZoomLevel.max ) {
      newValue = maxMinZoomLevel.max;
    }
    if ( newValue < maxMinZoomLevel.min ) {
      newValue = maxMinZoomLevel.min;
    }
    setZoomLevel( newValue );
    if ( state.viewer ) {
      if ( isCenter ) {
        const point = state.viewer.viewport.getBounds().getCenter();
        state.viewer.viewport.panTo( point );
        return;
      }
      state.viewer.viewport.zoomTo( newValue );
    }
  };

  const handleSwitchMulSelect = ( value ) => {
    setMulSelectMode( value );
  };
  const handleUpdateSingleAnno = async ( annotation ) => {
    handleOpen();
    if ( annotation.shapeType === SHAPE_TYPES.rect ) {
      annotation.target = {
        ...annotation.target,
        selector: convertSvgToSelector( annotation.target.selector )
      };
    }

    const rest = omit( annotation, [ 'description' ] );

    const shapeSelectorVal = get( rest, 'target.selector.value' );
    if ( shapeSelectorVal ) {
      // Remove inline svg styles
      rest.target = {
        ...rest.target,
        selector: {
          ...rest.target.selector,
          value: shapeSelectorVal.replace( /\sstyle=".+;"/, '' )
        }
      };
    }
    try {
      const response = await editAnnotation( {
        ...rest,
        id: annotation._id,
        upload: MCDSlideSelectedID
      } );
      if ( response?.data?.data ) {
        if ( annotation.tags.length > 0 && typeof annotation.tags[ 0 ] === 'string' ) {
          annotation.tags = getTagsObjectsFromIDs( annotation.tags );
        }
        if ( annotation.caseIdentifiers.length > 0 && typeof annotation.caseIdentifiers[ 0 ] === 'string' ) {
          annotation.caseIdentifiers = getCaseIdentifierObjectsFromIDs( annotation.caseIdentifiers );
        }
        dispatch( {
          type: 'UPDATE_ANNOTATION',
          payload: annotation
        } );
        emitAnnotationUpserted( annotation );
        updateGroup( MCDSlideSelectedID );
        handleClose();
        return true;
      }
    } catch ( ex ) {
      console.error( 'Error in handleUpdateSingleAnno:', ex );
    }

    return false;
  };

  const handleUpdateAnnotation = async ( annotationsToUpdate ) => {
    const isSingleAnnotation = annotationsToUpdate.length === 1;

    try {
      const updateAnnotation = async ( anno ) => {
        if ( isSingleAnnotation ) {
          const { title, tags, caseIdentifiers } = getCurrentAnnotationStorage();

          if ( !title || title.trim() === '' ) {
            const annotationParam = searchParams.get( 'annotation' );
            if ( annotationParam ) {
              searchParams.delete( 'annotation' );
              setSearchParams( searchParams.toString() );
            }
            removeCurrentAnnotationStorage();
            throw new Error( 'Update annotation failed' );
          }

          anno.title = title.trim();
          anno.tags = convertArray( tags );
          anno.caseIdentifiers = convertArray( caseIdentifiers );
        } else {
          anno.tags = getTagIdsFromTagObject( anno.tags );
          anno.caseIdentifiers = getCaseIdentifierIdsFromCaseIdentifierObject( anno.caseIdentifiers );
        }

        const success = await handleUpdateSingleAnno( anno );
        enqueueSnackbar( success ? 'Updated annotation' : 'Update annotation failed', {
          variant: success ? 'success' : 'error'
        } );
      };

      await Promise.all( annotationsToUpdate.map( updateAnnotation ) );
    } catch ( error ) {
      enqueueSnackbar( 'Update annotation failed', {
        variant: 'error'
      } );
    }
  };

  /**
   * Extracts tag IDs from an array of tag objects.
   *
   * @param {Array} tags - An array of tag objects.
   * @returns {Array} An array of tag IDs, excluding any null values.
   */
  const getTagIdsFromTagObject = ( tags ) => {
    return tags
      .map( ( tag ) => {
        return tag?.id || null;
      } )
      .filter( ( tag ) => tag !== null );
  };

  /**
   * Retrieves tag objects from an array of tag IDs.
   *
   * @param {Array<string>} tagIDs - An array of tag IDs
   * @returns {Array<Object>} An array of tag objects corresponding to the provided tag IDs.
   */
  const getTagsObjectsFromIDs = ( tagIDs ) => {
    return tagIDs
      .map( ( t ) => parseInt( t, 10 ) )
      .map( ( id ) => {
        return state.slide.tags.find( ( tag ) => tag.id === id ) || null;
      } )
      .filter( ( tag ) => tag !== null );
  };

  const getCaseIdentifierIdsFromCaseIdentifierObject = ( caseIdentifiers ) => {
    return caseIdentifiers
      .map( ( caseIdentifier ) => {
        return caseIdentifier?.id || null;
      } )
      .filter( ( caseIdentifier ) => caseIdentifier !== null );
  };

  /**
   * Retrieves case identifier objects from an array of case identifier IDs.
   *
   * @param {Array<string>} caseIdentifierIDs - An array of case identifier IDs.
   * @returns {Array<Object|null>} An array of case identifier objects or null if not found.
   */
  const getCaseIdentifierObjectsFromIDs = ( caseIdentifierIDs ) => {
    return caseIdentifierIDs
      .map( ( ci ) => parseInt( ci, 10 ) )
      .map( ( id ) => {
        return state.slide.caseIdentifiers.find( ( caseIdentifier ) => caseIdentifier.id === id ) || null;
      } );
  };

  const handleCreateAnnotation = async ( annotationsToCreate, movedAnnotations = [] ) => {
    handleOpen();
    const data = [];
    for ( let i = 0; i < annotationsToCreate.length; i++ ) {
      const annotation = annotationsToCreate[ i ];
      const shapeType = SHAPE_TYPES[ currentTool ];
      if ( !currentTool )
        continue; //! To avoid creating annotation when tool is not selected

      const {
        id,
        title,
        stroke,
        shapeType: currentType,
        ...restAnnotationFields
      } = annotation;

      const annoStorageData = getCurrentAnnotationStorage();

      const newAnnotation = {
        ...restAnnotationFields,
        slideId: state.slide._id,
        stroke: stroke ? stroke : state.color,
        shapeType: currentType ? currentType : shapeType,
        title: title ? title : annoStorageData.title,
        tags: convertArray( annoStorageData.tags ),
        caseIdentifiers: convertArray( annoStorageData.caseIdentifiers )
      };

      if ( isMCDFile ) {
        newAnnotation.target = { ...newAnnotation.target, source: window.location.href };
        newAnnotation.acquisition = state.MCDROIs.find( roi => roi.selected )?.id ?? null;
      }
      if ( newAnnotation.shapeType === SHAPE_TYPES.rect ) {
        newAnnotation.target.selector = convertSvgToSelector(
          newAnnotation.target.selector
        );
      }

      const shapeSelectorVal = get( newAnnotation, 'target.selector.value' );
      if ( shapeSelectorVal ) {
        // remove inline svg styles
        newAnnotation.target.selector.value = shapeSelectorVal.replace(
          /\sstyle=".+;"/,
          ''
        );
      }

      try {
        const { data: createdAnnotationData } = await createAnnotation( {
          ...newAnnotation
        } ).unwrap();
        if ( createdAnnotationData && createdAnnotationData._id ) {
          removeCurrentAnnotationStorage();
          const newAnnotationCreated = {
            ...createdAnnotationData,
            id: createdAnnotationData._id
          };
          emitAnnotationUpserted( newAnnotationCreated );
          await updateGroup( MCDSlideSelectedID );
          dispatch( {
            type: 'ADD_ANNOTATIONS',
            payload: [ newAnnotationCreated ]
          } );
          data.push( newAnnotationCreated );
        } else {
          removeCurrentAnnotationStorage();
          state.anno.removeAnnotation( annotation.id );
        }
        enqueueSnackbar( 'Create annotation', {
          variant: 'success'
        } );
      } catch ( error ) {
        console.error( 'error handleCreateAnnotation:', error );
        enqueueSnackbar( 'Create failed', {
          variant: 'error'
        } );
      }
    }
    state.anno.setAnnotations( [
      ...data,
      ...state.anno.getAnnotations().filter( ( ano ) => !ano.parentCopy && ano._id )
    ] );
    if ( movedAnnotations?.length ) {
      setSelectAnnotation( [ ...data ] );
    }

    if ( isMCDFile ) {
      dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: state.currentROI } );
    }
    //? Why navigate 
    // data.length &&
    //   navigate(
    //     `/slideViewer/${MCDSlideSelected}?annotation=${data[data.length - 1]?._id}`
    //   );
    handleClose();
  };

  const onClickAnnoAnalysis = () => {
    setOpenAnalysisModal( true );
  };

  const onClickAnnoAnalysisGraph = () => {
    setOpenAnalysisGraphModal( true );
  };

  const onClickMCDArea = () => {
    setOpenMCDAreaData( true );
  };

  const onClickAnnoAnalysisGraphComp = () => {
    setOpenAnalysisGraphCompSelectionModal( true );
  };

  //event for copy/paste annotation
  useCanvasEvent(
    state?.viewer,
    selectAnnotation,
    state?.anno,
    mulSelectMode,
    handleSwitchMulSelect,
    setSelectAnnotation,
    isSelectedAnnotation,
    setIsSelectedAnnotation,
    currentAnnotation,
    setCurrentAnnotation,
    handleUpdateAnnotation,
    handleCreateAnnotation,
    showAnnoDeleteAlert
  );

  const handleClose = () => {
    setBackdrop( false );
  };
  const handleOpen = () => {
    setBackdrop( true );
  };

  function downloadAnnotationFile() {
    if ( !projectData ) {
      enqueueSnackbar( 'Project data not available. Please try again.', {
        variant: 'error'
      } );
      return;
    }

    const date = moment().format( 'DDMMMYY' );
    exportAnnotations( {
      projectName: projectData?.name ?? '',
      slideName: slide.name,
      userEmail: slide.createdBy.email
    } ).then( ( response ) => {
      if ( response?.data?.success ) {
        const blob = new Blob( [ JSON.stringify( response.data.data, null, 2 ) ], {
          type: 'application/json'
        } );
        const a = document.createElement( 'a' );
        a.href = window.URL.createObjectURL( blob );
        a.download = `EXPORT_${ projectData.name }_${ slide.name }_${ date }.json`;
        document.body.appendChild( a ); //! we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        dispatch( { type: 'EXPORT_ANNOTATIONS' } );
      } else {
        enqueueSnackbar( 'Something wrong happened. Please try again', {
          variant: 'error'
        } );
      }
    } );
  }

  function uploadAnnotations() {
    inputFileRef.current.click();
  }

  const handleFileChange = ( fileList ) => {
    const files = [ ...Array( fileList.length ).keys() ].map( ( i ) => fileList[ i ] );

    files.map( ( file ) => {
      return new Promise( () => {
        state.uploadFile = file;
        setOverwriteAnno( true );
      } );
    } );

    inputFileRef.current.value = null;
  };

  function uploadAnalysis() {
    analysisFileRef.current.click();
  }
  const uploadAnalysisFile = async ( overwriteAnalysis ) => {
    setUploadingFile( true );
    setAnalysisFile( null );
    setOverwriteAnalysisFileAlert( false );
    const headers = {
      'Content-Type': 'multipart/form-data' // A default header
    };

    if ( organization ) {
      headers[ 'organization' ] = organization._id; // Add the Authorization header conditionally
    }
    const file = analysisFile;
    const formData = new FormData();
    formData.append( 'file', file );
    formData.append( 'slideId', MCDSlideSelectedID );
    formData.append( 'slideName', slide.name );
    formData.append( 'projectName', slide.project.name );
    formData.append( 'overwriteAnalysis', overwriteAnalysis );

    axios
      .post( '/annotations/uploadanalysis', formData, {
        headers: headers
      } )
      .then( async ( response ) => {
        await reloadBiomarkers();
        enqueueSnackbar( 'Analysis data uploaded successfully', {
          variant: 'success'
        } );
        setUploadingFile( false );
      } )
      .catch( () => {
        setUploadingFile( false );
        enqueueSnackbar( 'Something wrong happened. Please try again', {
          variant: 'error'
        } );
      } );
  };

  const handleAnalysisFileChange = async ( fileList ) => {
    const files = [ ...Array( fileList.length ).keys() ].map( ( i ) => fileList[ i ] );

    for ( const file of files ) {
      setAnalysisFile( file );
      setOverwriteAnalysisFileAlert( false );
    }
    analysisFileRef.current.value = null;
  };

  const annoFormatter = ( anno ) => {
    if ( anno.underlying.id ) {
      // in case of editing, apply the color of the annotation
      return anno.underlying.stroke
        ? {
          style: `stroke: ${ anno.underlying.stroke };`
        }
        : '';
    }
    // in case of drawing, apply the selected color
    return {
      className: 'custom',
      style: `stroke: ${ currentColor.current };fill:none !important;fillStyle:${ currentColor.current }`
    };
  };

  const InitOpenseadragon = ( slide ) => {
    const OSD_MCD_Config = { //* OSD means OpenSeaDragon
      compositeOperation: 'lighter', //! This helps with layer blending
      overlays: true,       // Enable overlays
      sequenceMode: false,  // Changed to false to allow overlapping
      tileSources: [],      // Empty tileSources to be filled later 
      showNavigator: false, //TODO Disable the navigator for now because it doesn't work properly with MCD.
    };

    let maxZoomLevel = maxMinZoomLevel.max;
    if ( slide && slide.lcmData && slide.lcmData.Scan ) {
      maxZoomLevel = slide.lcmData.Scan.Objective || 10;
      setMaxMinZoomLevel( {
        ...maxMinZoomLevel,
        max: maxZoomLevel
      } );
    }
    state.viewer && state.viewer.destroy();
    tilesMapRef.current.clear();
    handleOpen();

    let OSD_Config = {
      id: 'openSeaDragon',
      // SET MAX ZOOM ABLE TO BE 40x
      // zoomInButton: 'zoom-in',
      // zoomOutButton: 'zoom-out',
      constrainDuringPan: true,
      homeButton: 'home-button',
      maxZoomLevel,
      minZoomLevel: maxMinZoomLevel.min,
      navigatorAutoFade: false,
      navigatorPosition: 'BOTTOM_RIGHT',
      prefixUrl: '/openseadragon-images/',
      showNavigator: true,
      tileSources,
    };
    if ( isMCDFile ) {
      OSD_Config = { ...OSD_Config, ...OSD_MCD_Config };
    }
    const initViewer = OpenSeaDragon( OSD_Config );
    initViewer.addHandler( 'resize', function () {
      setTimeout( function () {
        initViewer.drawer.context.imageSmoothingEnabled = false;
      }, 1 );
    } );

    initViewer.addHandler( 'open', function () {
      const tiledImage = initViewer.world.getItemAt( 0 );
      if ( tiledImage.getFullyLoaded() ) {
        handleClose();
      } else {
        tiledImage.addOnceHandler( 'fully-loaded-change', handleClose );
      }
      setZoomLevel( initViewer.viewport.getZoom() );
      const openSeaDragonEl = document.getElementById( 'openSeaDragon' );
      if ( openSeaDragonEl ) {
        const DEFAULT_MPP = 0;
        const DEFAULT_PHYSICAL_WIDTH = 0;
        const DEFAULT_IMAGE_WIDTH = 1000;

        try {
          const mpp = slide?.metadata?.MPP || slide?.metadata?.mpp;
          if ( mpp && typeof mpp === 'number' && mpp > 0 ) {
            setZoomImageRatio( mpp );
          } else {
            const physicalWidth = slide?.physicalWidth || DEFAULT_PHYSICAL_WIDTH; // in micrometers
            const imageWidth = slide?.width || DEFAULT_IMAGE_WIDTH; // in pixels

            const ratio = ( physicalWidth > 0 && imageWidth > 0 )
              ? physicalWidth / imageWidth
              : DEFAULT_MPP;

            setZoomImageRatio( ratio );
          }
        } catch ( error ) {
          console.warn( 'Error in zoom ratio calculation, using default:', error );
          setZoomImageRatio( DEFAULT_MPP );
        }
      }
    } );

    initViewer.addHandler( 'animation', function () {
      setZoomLevel( initViewer.viewport.getZoom() );
    } );

    dispatch( { type: 'SET_STATE', key: 'viewer', value: initViewer } );

    const config = {
      color: currentColor.current,
      widgets: [
        AnnoFromWidget,
        {
          widget: AnnoInputWidget,
          id: 'currentAnnoTitle',
          name: 'title',
          label: 'Title',
          placeholder: 'Annotation title',
          autofocus: true,
          disabled: false,
          required: true,
          onEnterSubmit: true
        },
        {
          slideId: MCDSlideSelectedID,
          widget: AnnoAutocompleteWidget,
          id: 'currentAnnoTags',
          label: 'Tags',
          name: 'tags',
          placeholder: 'Annotation tags',
          autofocus: false,
          disabled: false,
          required: false,
          hasCheckbox: false
        },
        {
          slideId: MCDSlideSelectedID,
          widget: AnnoAutocompleteWidget,
          id: 'currentAnnoCaseIdentifiers',
          name: 'caseIdentifiers',
          label: 'Case Identifiers',
          placeholder: 'Case Identifiers',
          autofocus: false,
          disabled: false,
          required: false,
          hasCheckbox: false
        },
        {
          widget: AnnoAnalysisBtnWidget,
          onClick: onClickAnnoAnalysis
        },
        {
          widget: AnalysisGraphBtnWidget,
          onClick: onClickAnnoAnalysisGraph
        },
        {
          widget: AnalysisGraphCompBtnWidget,
          onClick: onClickAnnoAnalysisGraphComp
        },
        //TODO Add MCDAreaDataBtnWidget later. It's recommended to study first how does the Desktop version work.
        // { widget: AnnoMCDAreaDataBtnWidget, onClick: onClickMCDArea }
      ],
      readOnly: false,
      allowEmpty: true,
      drawOnSingleClick: true,
      formatters: [ annoFormatter ]
    };
    const annotate = Annotorious( initViewer, config );
    annotate.addDrawingTool( RubberbandRectangleTool );
    annotate.addDrawingTool( RubberbandEllipseTool );
    annotate.addDrawingTool( RubberbandFreehandTool );
    dispatch( { type: 'SET_STATE', key: 'anno', value: annotate } );
  };

  const initSlide = async ( slide ) => {
    if ( !MCDSlideSelectedID ) {
      return;
    }
    const { data } = await getFileById( MCDSlideSelectedID ).unwrap();
    dispatch( { type: 'SET_STATE', key: 'slide', value: data ?? slide } );
  };

  const switchToPanMode = () => {
    if ( state.anno ) {
      // handleSetTool('');
      state.anno.setDrawingEnabled( false );
    }
    setMulSelectMode( false );
    setPanMode( true );
  };

  const handleNavigateToSlideBox = () => {
    navigate( '/slidebox' );
  };

  const setLocalColorState = ( color ) => {
    dispatch( { type: 'SET_STATE', key: 'color', value: color } );
    currentColor.current = color;
    localStorage.setItem( 'color', JSON.stringify( color ) );
  };

  const handleColorChange = async ( color ) => {
    setLocalColorState( color );
    handleOpen();
    await saveSettings( { slide: MCDSlideSelectedID ?? state.MCDSlides[ 0 ]._id ?? state.slide._id, color } ).unwrap();
    handleClose();
  };

  const handleSetTool = ( value ) => {
    setCurrentTool( value );
    if ( value ) {
      setPanMode( false );
      handleSwitchMulSelect( false );
      dispatch( { type: 'SET_STATE', key: 'tool', value } );
      state.anno?.setDrawingEnabled( true );
    } else {
      state.anno?.setDrawingEnabled( false );
      dispatch( { type: 'SET_STATE', key: 'tool', value: "" } );
    }
  };

  const updateGroup = async ( id ) => {
    if ( !id ) return;
    const { data } = await getFileById( id ).unwrap();
    dispatch( {
      type: 'UPDATE_GROUP',
      payload: data.groups
    } );
  };
  //#endregion

  const fetchAnnotations = async ( slideId ) => {
    try {
      handleOpen();
      const result = await triggerAnnotationsQuery( slideId ).unwrap();
      if ( result?.data ) {
        dispatch( { type: 'ADD_ANNOTATIONS', payload: result.data } );
        if ( isMCDFile ) {
          dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: state.currentROI } );
        }
        else {
          state.anno?.setAnnotations( result.data );
        }
      }
      handleClose();
    } catch ( err ) {
      console.error( 'Failed to fetch annotations:', err );
    }
  };

  useEffect( () => {
    if ( !isMCDFile ) return;
    const slideSelectedId = state.MCDSlides.find( slide => slide.selected )?._id ?? null;
    if ( slideSelectedId ) {
      fetchAnnotations( slideSelectedId );
    }
  }, [ state.MCDSlides, isMCDFile ] );
  //#region USE EFFECTS
  useEffect( () => {
    const fetchProjectData = async () => {
      if ( !projectId ) return;

      setIsLoadingProject( true );
      try {
        const response = await getProjectDetails( projectId ).unwrap();
        setProjectData( response.data );
        dispatch( {
          type: 'SET_STATE',
          key: 'project',
          value: response.data
        } );
      } catch ( error ) {
        console.error( 'Failed to fetch project data:', error );
        enqueueSnackbar( 'Failed to load project data', {
          variant: 'error'
        } );
      } finally {
        setIsLoadingProject( false );
      }
    };

    fetchProjectData();
  }, [ projectId ] );

  useEffect( () => {
    if ( panMode ) {
      handleSetTool( "" );
    }
  }, [ panMode ] );

  useEffect( () => {
    // Watch for changes to the 'file' state
    if ( analysisFile !== null && overwriteAnalysisFileAlert === false ) {
      uploadAnalysisFile( false );
    }
  }, [ analysisFile, overwriteAnalysisFileAlert ] );

  /* Run when init component */
  useEffect( () => {
    initSlide( slide );
    InitOpenseadragon( slide );
    return () => {
      state.viewer && state.viewer.destroy();
    };
  }, [ slide, annotationsData ] );

  /**
   * when change group, it will change default color of annotation shapers
   */
  useEffect( () => {
    selectAnnotationsOfGroup();
    setLocalColorState( state.color );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ state.selectedGroupChange ] );

  /**
   * When color will be changed for selected annotation, it will call API to the backend API to set changed color.
   */
  useEffect( () => {
    if ( state.selectedAnno.id ) {
      setLocalColorState( state.selectedAnno.stroke );
    }
  }, [ state.selectedAnno.id, state.selectedAnno.stroke ] );

  useEffect( () => {
    if ( state.anno ) {
      initSelectedAnno( selectAnnotation );
    }
  }, [ state.anno?.getAnnotations(), selectAnnotation, state.viewer ] );

  /* Init Annotation tools */
  useEffect( () => {
    if ( annotationsData ) {
      dispatch( { type: 'ADD_ANNOTATIONS', payload: annotationsData } );
    }
    if ( isMCDFile && state.currentROI ) {
      dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: state.currentROI } );
    } else if ( state.anno ) {
      state.anno.setAnnotations( annotationsData );
    }

    return () => {
      state.viewer && state.viewer.destroy();
    };
  }, [ state.anno, annotationsData, state.viewer ] );

  useEffect( () => {
    if ( state.anno ) {
      state.anno.on( 'createAnnotation', async ( annotation ) => {
        dispatch( {
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        } );
        const { title } = getCurrentAnnotationStorage();
        if ( !title || title?.trim() === '' ) {
          state.anno.removeAnnotation( annotation.id );
          const annotationParam = searchParams.get( 'annotation' );
          if ( annotationParam ) {
            searchParams.delete( 'annotation' );
            setSearchParams( searchParams.toString() );
          }
          enqueueSnackbar( 'Create failed, Annotation Title is required', {
            variant: 'error'
          } );
          removeCurrentAnnotationStorage();
          return;
        }
        await handleCreateAnnotation( [ annotation ] );
        state.anno.setDrawingEnabled( false );
        switchToPanMode();
      } );

      state.anno.on( 'deleteAnnotation', ( annotation ) => {
        const prevAnnotation = state.anno.getAnnotations();
        state.anno.setAnnotations( [ ...prevAnnotation, annotation ] );
        state.anno.selectAnnotation( annotation );
        if ( annotation.id ) {
          showAnnoDeleteAlert( [ annotation ] );
        }
        setPanMode( true );
      } );

      state.anno.on( 'updateAnnotation', async ( annotation, previous ) => {
        dispatch( {
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        } );
        if ( annotation.id ) {
          await handleUpdateAnnotation( [ annotation ] );
        }
        setPanMode( true );
      } );

      return () => {
        state.anno.off( 'createAnnotation' );
        state.anno.off( 'deleteAnnotation' );
        state.anno.off( 'updateAnnotation' );
      };
    }
  }, [ state.anno, state.tool, selectAnnotation, ] );

  //change cursor when click ctrl
  useEffect( () => {
    if ( mulSelectMode ) {
      const elements = document.querySelectorAll( '.a9s-annotation' );
      elements.forEach( ( el ) => {
        const childElements = el.children;
        if ( childElements.length && childElements[ 1 ] ) {
          childElements[ 1 ].style.cursor = 'copy';
        }
      } );
    } else {
      const elements = document.querySelectorAll( '.a9s-annotation' );
      elements.forEach( ( el ) => {
        const childElements = el.children;
        if ( childElements.length && childElements[ 1 ] ) {
          childElements[ 1 ].style.cursor = 'pointer';
        }
      } );
    }
  }, [ mulSelectMode ] );

  /**
   * Binding event handler to sync URL search param with selected annotation
   */
  useEffect( () => {
    if ( state.anno ) {
      state.anno.on( 'mouseEnterAnnotation', function ( annotation ) {
        const element = document.querySelector( '.a9s-annotation.hover' );
        const childElements = element && element.children;
        if ( childElements && childElements.length > 1 ) {
          addStyleToAnnotation( childElements[ 1 ], annotation );
        }
        if ( selectAnnotation.length && !mulSelectMode ) {
          if ( isSelectedAnnotation ) return;
          const findAnno = selectAnnotation.find(
            ( v ) => v?._id === annotation._id
          );
          if ( findAnno?._id ) {
            setCurrentAnnotation( annotation );
            setIsSelectedAnnotation( true );
            return;
          } else {
            setCurrentAnnotation( null );
            setIsSelectedAnnotation( false );
          }
        }
      } );
      state.anno.on( 'mouseLeaveAnnotation', function ( annotation, element ) {
        const childElements = element.children;
        if ( childElements && childElements.length > 1 ) {
          removeStyleFromAnnotation( childElements[ 1 ], annotation );
        }
        setIsSelectedAnnotation( false );
      } );
      state.anno.on( 'selectAnnotation', function ( data, element ) {
        setCurrentTool( "" );
        if ( !data ) return;
        setGroup1Id( data.group );
        dispatch( {
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: data
        } );
        setCurrentAnnotationStorage( data );
        const childElements = element.children;
        if ( childElements && childElements.length > 0 ) {
          addStyleToAnnotationAndPointer( childElements, data );
        }

        if ( mulSelectMode ) {
          const allAnnotations = state.anno
            .getAnnotations()
            .map( ( currentAnno ) => {
              const shapeSelector = get( currentAnno, 'target.selector' );
              if (
                currentAnno.shapeType === SHAPE_TYPES.rect &&
                shapeSelector.type === 'SvgSelector'
              ) {
                currentAnno = {
                  ...currentAnno,
                  target: {
                    ...currentAnno.target,
                    selector: convertSvgToSelector( shapeSelector )
                  }
                };
                return currentAnno;
              }
              return currentAnno;
            } );
          const cloneData = allAnnotations.find( ( v ) => v._id === data._id );
          const exitedAnno = selectAnnotation.find(
            ( v ) => v._id === cloneData._id
          );
          let newSelectedListAnno = [ ...selectAnnotation ];
          if ( exitedAnno?._id ) {
            newSelectedListAnno = selectAnnotation.filter(
              ( v ) => v._id !== cloneData._id
            );
          } else {
            newSelectedListAnno = [ ...newSelectedListAnno, cloneData ];
          }
          setSelectAnnotation( newSelectedListAnno );
          state.anno.setAnnotations( allAnnotations );
        } else {
          unSelectedAnno( selectAnnotation );
          setSelectAnnotation( [] );
          const annotationParam = searchParams.get( 'annotation' );
          if ( annotationParam !== data._id ) {
            searchParams.set( 'annotation', data._id );
            setSearchParams( searchParams.toString() );
          }
        }
      } );

      state.anno.on( 'cancelSelected', function () {
        dispatch( {
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        } );
        const annotationParam = searchParams.get( 'annotation' );
        removeCurrentAnnotationStorage();
        if ( annotationParam ) {
          searchParams.delete( 'annotation' );
          setSearchParams( searchParams.toString() );
        }
        setPanMode( true );
      } );
      return () => {
        state.anno.off( 'selectAnnotation' );
        state.anno.off( 'cancelSelected' );
      };
    }
  }, [ state.anno, mulSelectMode, selectAnnotation, currentAnnotation ] );


  useEffect( () => {
    if ( state.anno && !isMCDFile ) {
      state.anno.setAnnotations( state.annotations );
    }
  }, [ state.annotations, state.slide.groups, state.anno, isMCDFile ] );

  useEffect( () => {
    if ( state.anno ) {
      state.anno.on( 'createSelection', function ( selection ) {
        const element = document.querySelector(
          '.a9s-annotation.editable.selected'
        );
        const childElements = element.children;
        if ( childElements && childElements.length > 0 ) {
          addStyleToAnnotationAndPointer( childElements, {
            stroke: state.color
          } );
        }
      } );
    }
  }, [ state.anno ] );

  useEffect( () => {
    removeCurrentAnnotationStorage();
  }, [] );

  useEffect( () => {
    if ( mcdFileData ) {
      dispatch( { type: 'SET_STATE', key: 'mcd', value: { ...mcdFileData } } );

      const { slides } = mcdFileData;
      const { MCDSlides, MCDROIs } = getStoredMCDState( slide._id );
      const mergedSlides = slides.map( newSlide => ( {
        ...newSlide,
        selected: MCDSlides?.includes( newSlide._id ) || false
      } ) );
      // Ensure at least one slide is selected
      if ( !mergedSlides.some( s => s.selected ) && mergedSlides.length > 0 ) {
        mergedSlides[ 0 ].selected = true;
      }

      setMCDSlides( mergedSlides );
      dispatch( { type: 'SET_MCD_SLIDES', payload: mergedSlides } );

      if ( mergedSlides.length > 0 ) {
        mergedSlides.forEach( ( slide ) => {
          const { acquisitions: rois } = mergedSlides[ 0 ];
          const mergedROIs = rois.map( ( roi, index ) => ( {
            ...roi,
            selected: MCDROIs.includes( roi.id )
          } ) );
          dispatch( { type: 'SET_MCD_ROIS', payload: mergedROIs } );
          dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: mergedROIs.find( mr => mr.selected )?.id } );
        } );

        getFileById( mergedSlides[ 0 ]._id ).unwrap()
          .then( ( response ) => {
            if ( response.success ) {
              dispatch( { type: 'SET_STATE', key: 'slide', value: response.data } );
              setMCDSlideSelectedID( mergedSlides[ 0 ]._id );
            }
            handleClose();
          } )
          .catch( ( error ) => {
            console.error( 'Error fetching slide data:', error );
          } );
      }
    }
  }, [ mcdFileData ] );

  useEffect( () => {
    if ( !isMCDFile ) return;
    if ( state.MCDSlides.length === 0 ) return;

    const slideSelected = state.MCDSlides.find( slide => slide.selected );

    if ( slideSelected ) {
      if ( slideSelected.id !== MCDSlideSelectedID ) {
        setMCDSlideSelectedID( slideSelected.id );

        let acquisitions = slideSelected.acquisitions || [];
        const { MCDROIs } = getStoredMCDState( state.mcd._id );
        if ( MCDROIs.length === 0 ) {
          dispatch( {
            type: 'SET_MCD_ROIS',
            payload: acquisitions
          } );
        }
      }
    }
    // Select first slide if none selected
    else if ( !state.MCDSlides.some( s => s.selected ) && state.MCDSlides.length > 0 ) {
      dispatch( {
        type: 'SELECT_MCD_SLIDE',
        payload: state.MCDSlides[ 0 ].name
      } );
    }
  }, [ state.MCDSlides, MCDSlideSelectedID, isMCDFile ] );

  useEffect( () => {
    if ( !isMCDFile ) return;
    const ROISelected = state.MCDROIs.find( roi => roi.selected );
    if ( ROISelected ) {
      const { channels_count, channels_names = [], channels_labels = [], image_path, id: roiId } = ROISelected;

      dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: roiId } );

      const { MCDLayers: savedLayers } = getStoredMCDState( slide._id );
      const layers = Array.from( { length: channels_count }, ( _, i ) => ( {
        id: i,
        channel: channels_names[ i ] || `Channel ${ i + 1 }`,
        label: channels_labels[ i ] || `Label ${ i + 1 }`,
        selected: savedLayers.some( l => l.id === i ),
        originalIndex: i,
        color: savedLayers.find( s => s.id === i )?.color ?? MCDLayerColor.BLACK,
        urlImage: `${ URL_STORAGE }${ encodeURIComponent( image_path ) }/${ channels_names[ i ] }.tiff`
      } ) );
      if ( !layers.some( l => l.selected ) ) {
        layers[ 0 ].selected = true;
      }
      dispatch( { type: 'SET_MCD_LAYERS', payload: layers } );
    } else if ( state.MCDROIs.length > 0 ) {
      dispatch( { type: 'SELECT_MCD_ROI', payload: state.MCDROIs[ 0 ].description } );
      dispatch( { type: 'FILTER_ANNOTATIONS_BY_ROI', payload: state.MCDROIs[ 0 ].id } );
    }
  }, [ state.MCDROIs, isMCDFile ] );

  // update optionsTags for Widget
  useEffect( () => {
    if ( state.slide?.tags && state.anno ) {
      const options = state.slide?.tags.map( ( v ) => ( {
        label: v.name,
        value: v.id
      } ) );
      localStorage.setItem(
        `widget-options-tags-${ state?.slide?._id }`,
        JSON.stringify( options )
      );
      // close anno select
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      setSelectAnnotation( [] );
      dispatch( { type: 'RELOAD_ANNOTATIONS_TAG' } );
      updateGroup( MCDSlideSelectedID );
    }
  }, [ state.slide?.tags, state.anno ] );

  useEffect( () => {
  }, [ state.slide ] );


  // update optionsCaseIdentifiers for Widget
  useEffect( () => {
    if ( state.slide?.caseIdentifiers && state.anno ) {
      const options = state.slide?.caseIdentifiers.map( ( v ) => ( {
        label: `Patient Id: ${ v.patientId }`,
        value: v.id
      } ) );
      localStorage.setItem(
        `widget-options-caseIdentifiers-${ state?.slide?._id }`,
        JSON.stringify( options )
      );
      // close anno select
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      setSelectAnnotation( [] );
      dispatch( { type: 'RELOAD_ANNOTATIONS_CASE_IDENTIFIER' } );
      updateGroup( MCDSlideSelectedID );
    }
  }, [ state.slide?.caseIdentifiers, state.anno ] );

  function removeBackgroundFilter( context, callback ) {
    const backgroundColor = { r: 255, g: 255, b: 255 }; // The background color
    const tolerance = 0; //* The tolerance parameter defines the color matching threshold for background transparency, accommodating minor color variations from compression, anti-aliasing, or lighting differences.
    const imgData = context.getImageData( 0, 0, context.canvas.width, context.canvas.height );
    const data = imgData.data;


    for ( let i = 0; i < data.length; i += 4 ) {
      const r = data[ i ];
      const g = data[ i + 1 ];
      const b = data[ i + 2 ];

      // Check if the pixel color is within the tolerance range of the background color
      if (
        Math.abs( r - backgroundColor.r ) <= tolerance &&
        Math.abs( g - backgroundColor.g ) <= tolerance &&
        Math.abs( b - backgroundColor.b ) <= tolerance
      ) {
        data[ i + 3 ] = 0; // Set alpha to 0 (fully transparent)
      }
    }

    context.putImageData( imgData, 0, 0 );

    callback();
  }

  function findMostCommonColor() {
    const DARK_THRESHOLD = 128;
    const SQUARE_SIZE = 100;
    const canvas = state.viewer?.drawer?.canvas;
    if ( !canvas ) {
      return { found: false, message: 'Canvas not available' };
    }

    const ctx = canvas.getContext( '2d' );

    const centerX = Math.floor( ( canvas.width - SQUARE_SIZE ) / 2 );
    const centerY = Math.floor( ( canvas.height - SQUARE_SIZE ) / 2 );

    const imgData = ctx.getImageData( centerX, centerY, SQUARE_SIZE, SQUARE_SIZE );
    const data = imgData.data;
    const colorMap = new Map();

    for ( let i = 0; i < data.length; i += 4 ) {
      const r = data[ i ], g = data[ i + 1 ], b = data[ i + 2 ], a = data[ i + 3 ];
      if ( a < 1 ) continue;

      let key;
      if ( r < DARK_THRESHOLD && g < DARK_THRESHOLD && b < DARK_THRESHOLD ) {
        key = 'rgb(0,0,0)';
      } else {
        key = `rgb(${ r },${ g },${ b })`;
      }

      colorMap.set( key, ( colorMap.get( key ) || 0 ) + 1 );
    }
    if ( colorMap.size === 0 ) {
      return { found: false, message: 'No non-transparent pixels in region' };
    }
    let mostCommonColor = null, maxCount = 0;
    for ( const [ colorString, count ] of colorMap.entries() ) {
      if ( count > maxCount ) {
        maxCount = count;
        mostCommonColor = colorString;
      }
    }

    const rgbValues = mostCommonColor.match( /\d+/g ).map( Number );
    const percentage = maxCount / ( data.length / 4 );
    const hasGrayBackground = rgbValues.every( ( val, index ) => val <= 124 ) && percentage > GRAY_COLOR_PERCENTAGE_LIMIT;
    return {
      found: true,
      message: `Most common color: ${ mostCommonColor } (Count: ${ maxCount })`,
      color: mostCommonColor,
      percentage,
      hasGrayBackground
    };
  }

  /**
 * Adds color filters to multiple image layers in an OpenSeadragon viewer
 * @param {Array<{color: string}>} selectedLayers - Array of layer objects with color properties
 * @throws {Error} If viewer is not initialized or layers are invalid
 */
  function addFiltersToMultiLayerImages( selectedLayers ) {
    const { viewer } = state;

    if ( !viewer ) {
      throw new Error( 'Viewer must be initialized before adding filters' );
    }

    if ( !Array.isArray( selectedLayers ) ) {
      throw new Error( 'selectedLayers must be an array' );
    }

    const generateColorMap = ( color ) => {
      if ( colorMapCache.has( color ) ) {
        return colorMapCache.get( color );
      }

      const rgb = MCDLayerColorMapToRGB[ color ] || { r: 255, g: 255, b: 255 };
      const colorMap = Array.from( { length: 256 }, ( _, i ) => {
        const ratio = i / 255;
        return [
          rgb.r * ratio,
          rgb.g * ratio,
          rgb.b * ratio,
        ];
      } );

      colorMapCache.set( color, colorMap );
      return colorMap;
    };

    const layerFilters = selectedLayers
      .map( ( layer ) => {
        const item = layer.item; // Use stored item reference
        if ( !item ) {
          console.warn( 'Item not found for layer:', layer );
          return null;
        }

        const color = layer.color || MCDLayerColor.BLACK;
        const colorMap = generateColorMap( color );

        return {
          items: item,
          loadMode: 'sync',
          processors: [
            OpenSeaDragon.Filters.INVERT(),
            removeBackgroundFilter,
            OpenSeaDragon.Filters.BRIGHTNESS( -70 ),
            OpenSeaDragon.Filters.COLORMAP( colorMap, 128 ),
          ],
        };
      } )
      .filter( Boolean ); // Remove null entries

    try {
      viewer.setFilterOptions( { filters: layerFilters } );
    } catch ( error ) {
      console.error( 'Failed to set filter options:', error );
      throw error;
    }
  }

  useEffect( () => {
    if ( !isMCDFile || !state.viewer ) {
      console.debug( 'Viewer not ready or non-MCD file' );
      return;
    }
    //* This ensures that asynchronous operations terminate when the component unmounts, preventing memory leaks and eliminating dangling references to unmounted components.
    let isComponentMounted = true;

    const loadImages = async () => {
      try {
        const selectedLayers = state.MCDLayers.filter( layer => layer.selected );

        state.viewer.world.removeAll();
        tilesMapRef.current.clear();

        const imageLoadPromises = selectedLayers.map( ( layer, index ) =>
          new Promise( async ( resolve, reject ) => {
            if ( tilesMapRef.current.has( layer.id ) ) {
              resolve();
              return;
            }

            // Get the path to the .dzi file 
            const dziUrl = layer.urlImage + '/meta.dzi';

            // Fetch the .dzi file
            let response = await fetch( dziUrl );
            if ( !response.ok ) {
              console.error( `Failed to fetch DZI file for layer ${ layer.id }` );
              reject( `Failed to fetch DZI file for layer ${ layer.id }` );
              return;
            }
            const dziText = await response.text();

            // Parse the XML from .dzi
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString( dziText, 'application/xml' );

            // Extract properties from XML
            const imageNode = xmlDoc.getElementsByTagName( 'Image' )[ 0 ];
            const tileSize = parseInt( imageNode.getAttribute( 'TileSize' ), 10 );
            const overlap = parseInt( imageNode.getAttribute( 'Overlap' ), 10 );
            const format = imageNode.getAttribute( 'Format' );
            // const xmlns = imageNode.getAttribute( 'xmlns' );
            const sizeNode = imageNode.getElementsByTagName( 'Size' )[ 0 ];
            const width = parseInt( sizeNode.getAttribute( 'Width' ), 10 );
            const height = parseInt( sizeNode.getAttribute( 'Height' ), 10 );

            // Build custom tileSource object
            const tileSource = {
              width: width,
              height: height,
              tileSize: tileSize,
              tileOverlap: overlap,
              getTileUrl: ( level, x, y ) => {
                return `${ layer.urlImage }/${ level }/${ x }_${ y }.${ format }`;
              }
            };

            // Add image to viewer
            state.viewer.addTiledImage( {
              index,
              tileSource,
              compositeOperation: 'multiply',
              crossOriginPolicy: 'Anonymous',
              opacity: 0.5,
              x: 0,
              y: 0,
              width: 1,
              success: ( event ) => {
                if ( isComponentMounted ) {
                  layer.item = event.item;
                  tilesMapRef.current.set( layer.id, true );
                  if ( index === selectedLayers.length - 1 ) {
                    addFiltersToMultiLayerImages( selectedLayers );

                    setTimeout( () => {
                      if ( isComponentMounted && state.viewer ) {
                        const result = findMostCommonColor();
                        console.log( '🚀 ~ findMostCommonColor ~ result:', result );
                        if ( result.hasGrayBackground && state.rerenderCount < MAX_RERENDER_COUNT ) {
                          handleRepaint();
                        }
                      }
                    }, 6000 ); // Delay to allow time for filters to apply
                  }
                  resolve( event );
                }
              },
              error: ( error ) => {
                console.error( `Failed to load image for layer ${ layer.id }:`, error );
                reject( error );
              }
            } );
          } )
        );

        await Promise.all( imageLoadPromises )
          .catch( error => console.error( 'Error loading images:', error ) );

      } catch ( error ) {
        console.error( 'Fatal error in image loading:', error );
      }
    };
    loadImages();

    return () => {
      isComponentMounted = false;
      tilesMapRef.current.clear();
      if ( state.viewer ) {
        state.viewer.world.removeAll();
      }
    };

  }, [ state.MCDLayers, state.viewer, isMCDFile ] );
  //#endregion

  const selectAnnotationsOfGroup = () => {
    const tempAnno = [];
    if ( state.anno ) {
      state.annotations.forEach( ( annotation ) => {
        if ( annotation.stroke === state.color ) {
          tempAnno.push( annotation );
        }
      } );
      setPanMode( true );
      setSelectAnnotation( [] );
      setSelectAnnotation( tempAnno );
    }
  };
  const handleDeleteAnnotation = async () => {
    if ( deleteAnno.length ) {
      hideAnnoDeleteAlert();
      handleOpen();
      for ( const anno of deleteAnno ) {
        await handleDeleteSingleAnno( anno );
      }
      enqueueSnackbar( 'Deleted annotation successfully', {
        variant: 'success'
      } );
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      dispatch( { type: 'SET_STATE', key: 'selectedAnno', value: {} } );
      setSelectAnnotation( [] );
    }
  };

  const handleDeleteSingleAnno = async ( anno ) => {
    if ( !anno?._id ) return;
    try {
      await deleteAnnotation( anno._id );
      dispatch( {
        type: 'DELETE_ANNOTATION',
        payload: anno._id
      } );
      emitAnnotationDeleted( { slide, _id: anno._id } );
      updateGroup( MCDSlideSelectedID );
    } catch ( e ) {
      enqueueSnackbar( e.toString(), {
        variant: 'error'
      } );
    }
  };

  const cancelDeleteAnnotation = () => {
    const prevAnnotation = state.anno.getAnnotations();
    const checkExitedAnno = prevAnnotation.find(
      ( v ) => v._id === deleteAnno[ 0 ]._id
    );
    if ( !checkExitedAnno?._id ) {
      state.anno.setAnnotations( [ ...prevAnnotation, ...deleteAnno ] );
    }
    hideAnnoDeleteAlert();
  };

  const handleOverwriteAnnotation = () => {
    state.isOverwrite = true;
    postAnnotationsData();
    setOverwriteAnno( false );
    state.uploadFile = null;
  };

  const cancelOverwriteAnnotation = () => {
    state.isOverwrite = false;
    postAnnotationsData();
    setOverwriteAnno( false );
    state.uploadFile = null;
  };

  const postAnnotationsData = () => {
    const formData = new FormData();
    formData.append( 'file', state.uploadFile );
    formData.append( 'slideName', slide.name );
    formData.append( 'isOverwrite', state.isOverwrite );
    formData.append( 'slideId', MCDSlideSelectedID );

    const headers = {
      'Content-Type': 'multipart/form-data' // A default header
    };

    if ( organization ) {
      headers[ 'organization' ] = organization._id; // Add the Authorization header conditionally
    }
    setUploadingAnnotations( true );

    axios
      .post( '/annotations/uploadxml', formData, {
        headers: headers
      } )
      .then( async ( response ) => {
        dispatch( { type: 'SET_STATE', key: 'annotations', value: [] } );
        refetchAnnotations && ( await refetchAnnotations() );
        setUploadingAnnotations( false );
        enqueueSnackbar( 'Annotation data uploaded successfully', {
          variant: 'success'
        } );
      } )
      .catch( () => {
        enqueueSnackbar( 'Something wrong happened. Please try again', {
          variant: 'error'
        } );
      } );
  };

  const hideUploadAnnoConfirm = () => {
    setUploadAnnoConfirm( false );
  };

  const setCurrentAnnotationStorage = ( { title, tags, caseIdentifiers } ) => {
    localStorage.setItem( 'currentAnnoTitle', title );
    localStorage.setItem(
      'currentAnnoTags',
      getTagIdsFromTagObject( tags ).join()
    );
    localStorage.setItem(
      'currentAnnoCaseIdentifiers',
      getCaseIdentifierIdsFromCaseIdentifierObject( caseIdentifiers ).join()
    );
  };
  const removeCurrentAnnotationStorage = () => {
    localStorage.removeItem( 'currentAnnoTitle' );
    localStorage.removeItem( 'currentAnnoTags' );
    localStorage.removeItem( 'currentAnnoCaseIdentifiers' );
  };
  const getCurrentAnnotationStorage = () => {
    const title = localStorage.getItem( 'currentAnnoTitle' ) || '';
    const tags = localStorage.getItem( 'currentAnnoTags' ) || [];
    const caseIdentifiers =
      localStorage.getItem( 'currentAnnoCaseIdentifiers' ) || [];
    return { title, tags, caseIdentifiers };
  };
  const compareWithChange = ( group ) => {
    setGroup2Id( group.id );
    let annoFromGroup;
    state.annotations.forEach( ( anno ) => {
      if ( anno.stroke === group.Color ) {
        annoFromGroup = anno;
        return;
      }
    } );
    setCompareAnno( annoFromGroup );
    setOpenAnalysisGraphCompSelectionModal( false );
    setOpenAnalysisGraphCompModal( true );
  };

  const reloadBiomarkers = async () => {
    try {
      const listAnnoId = state.annotations.map( ( anno ) => anno._id );
      const biomarkers = await Promise.all(
        listAnnoId.map( async ( annoId ) => {
          const data = await getBiomarkerByAnnotationId( annoId ).unwrap();
          return { [ annoId ]: data };
        } )
      );

      const biomarkersObj = biomarkers?.reduce( ( acc, cur ) => {
        return { ...acc, ...cur };
      }, {} );
      dispatch( { type: 'RELOAD_BIOMARKERS', payload: biomarkersObj } );
    } catch ( _ ) { }
  };

  const listAnnoIdS = state.annotations.map( ( anno ) => anno._id ).join( ',' );
  useEffect( () => {
    reloadBiomarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ listAnnoIdS ] );

  useEffect( () => {
    if ( projectData && !isLoadingProject ) {
      dispatch( {
        type: 'SET_STATE',
        key: 'project',
        value: projectData
      } );
    }
  }, [ projectData, isLoadingProject ] );
  useEffect( () => {
    const currentSlideID = isMCDFile
      ? state.MCDSlides.find( slide => slide.selected )?._id
      : slide._id;
    if ( currentSlideID ) {
      setMCDSlideSelectedID( currentSlideID );
    }
  }, [ isMCDFile, state.MCDSlides, slide._id ] );

  return (
    <SlideViewerContext.Provider value={[ state, dispatch ]}>
      <Box
        sx={{
          flexGrow: '1',
          marginTop: '0px',
          maxHeight: 'calc(100vh - 66px)',
          marginLeft: '0px',
          display: 'flex'
        }}
      >
        <SlideManager slideId={MCDSlideSelectedID} isMCD={isMCDFile} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 8px 8px 8px',
            flexGrow: '1'
          }}
        >
          <DescSec
            sx={{
              backgroundColor: '#f8f8f8',
              border: 'none',
              mb: 0,
              pb: 1
            }}
          >
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
            >
              <ToolButton
                id="pan-to"
                onClick={switchToPanMode}
                ariaLabel="Pan Mode"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
                className={`tool-btn ${ panMode ? 'active' : '' }`}
              >
                <OpenWithIcon />
              </ToolButton>
              <ToolButton
                onClick={() => handleZoomChange( 1, true )}
                id="home-button"
                ariaLabel="Focus back"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <CenterFocusWeakIcon />
              </ToolButton>

              <ToolButton
                onClick={() => handleZoomChange( zoomLevel + 1 )}
                id="zoom-in"
                ariaLabel="Zoom In"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <ZoomIn />
              </ToolButton>
              <ToolButton
                onClick={() => handleZoomChange( zoomLevel - 1 )}
                id="zoom-out"
                ariaLabel="Zoom Out"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <ZoomOut />
              </ToolButton>
              <DrawToolbar
                anno={state.anno}
                context={[ currentTool, handleSetTool ]}
              />
              <MuiColorInput
                className="custom-color-picker"
                sx={{ margin: '4px', padding: '7px' }}
                value={state.color}
                onChange={handleColorChange}
                format="hex8"
              />
              {/* //TODO Add support for MCD Files */}
              {!isMCDFile && <>
                <ToolButton
                  ariaLabel="Export Slide Data"
                  sx={{ display: 'flex!important', borderRadius: '0px' }}
                  // disabled={!state.annotations.length}
                  onClick={downloadAnnotationFile}
                >
                  <SystemUpdateAltIcon />
                </ToolButton>
                {!open && (
                  <IconButton
                    disableRipple
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen( true );
                    }}
                  >
                    <InfoOutlined fontSize="inherit" color="info" />
                  </IconButton>
                )}
                <ToolButton
                  ariaLabel="Upload annotations"
                  sx={{ display: 'flex!important', borderRadius: '0px' }}
                  // disabled={!state.annotations.length}
                  onClick={uploadAnnotations}
                >
                  <UploadIcon />
                  <input
                    id="file-upload"
                    type="file"
                    accept={inputAccept}
                    style={{ display: 'none' }}
                    ref={inputFileRef}
                    onChange={( e ) => handleFileChange( e.target.files )}
                  />
                </ToolButton>

                <ToolButton
                  ariaLabel="Upload analysis file"
                  sx={{ display: 'flex!important', borderRadius: '0px' }}
                  // disabled={!state.annotations.length}
                  onClick={uploadAnalysis}
                >
                  <ShowChartIcon />
                  <input
                    id="analysis-file-upload"
                    type="file"
                    accept={analysisFileAccept}
                    style={{ display: 'none' }}
                    ref={analysisFileRef}
                    onChange={( e ) => handleAnalysisFileChange( e.target.files )}
                  />
                </ToolButton>
              </>
              }
              <ZoomSlider
                zoomLevel={zoomLevel}
                min={maxMinZoomLevel.min}
                max={maxMinZoomLevel.max}
                handleZoomChange={handleZoomChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1,
                padding: '0px 8px',
                gap: 1
              }}
            >
              <Button
                startIcon={<ArrowBack />}
                variant="outlined"
                onClick={handleNavigateToSlideBox}
              >
                Back
              </Button>
              {!isMCDFile &&
                <>
                  <MoveButton entity={slide} />
                  <CopyButton entity={slide} />
                  <ShareButton entities={[ slide ]} calledFrom="slideViewer" />
                </>
              }
              {isMCDFile && (
                <>
                  <RepaintButton
                    onClick={handleRepaint}
                    disabled={!state.MCDLayers.some( layer => layer.selected )}
                  />
                  <SaveButton
                    onClick={handleSaveImage}
                    disabled={!state.MCDLayers.some( layer => layer.selected )}
                  />
                </>
              )}
              {/* <InviteButton
                slideId={MCDSlideSelected}
                createdBy={slide.createdBy?._id}
              /> */}
            </Box>
          </DescSec>
          <Box
            sx={{
              height: '100%',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              ...state.annotationStyles,
              cursor: panMode ? 'move' : 'crosshair',
              position: 'relative',
              zIndex: 2,
              overflow: 'hidden'
            }}
            id="openSeaDragon"
          >
            {zoomImageRatio > 0 && (
              <Stack
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  zIndex: 50
                }}
              >
                <Typography textAlign="center">
                  {( zoomImageRatio * zoomLevel ).toFixed( 2 )} um
                </Typography>
                <Box sx={{
                  width: '150px',
                  height: '10px',
                  marginTop: '-5px',
                  borderWidth: '0 2px 2px',
                  borderStyle: 'solid',
                  borderColor: 'black'
                }} />
              </Stack>
            )}
          </Box>
        </Box>
        <SlideRightPanel
          slideId={state.slide._id}
          open={slideRightPanelOpen}
          selectedAnnotation={state.selectedAnno}
          handleToggle={( isOpen ) => setSlideRightPanelOpen( isOpen )}
        />
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: 1202 }} open={backdrop}>
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Loading Slide...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: 1202 }} open={uploadingFile}>
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Uploading analysis file
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1202 }}
        open={uploadingAnnotations}
      >
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Uploading annotations
          </Typography>
        </Box>
      </Backdrop>
      <Alert
        open={deleteAnno}
        title="Delete annotation"
        content="Are you sure to delete this annotation?"
        onDismiss={cancelDeleteAnnotation}
        onConfirm={handleDeleteAnnotation}
      />
      <Alert
        open={overwriteAnno}
        title="Overwrite annotations"
        content="Are you sure want to overwrite existing annotations?"
        onDismiss={cancelOverwriteAnnotation}
        onConfirm={handleOverwriteAnnotation}
        confirmBtn="Yes"
        cancelBtn="No"
      />
      <InfoAlert
        open={uploadAnnoConfirm}
        title="Import Annotation"
        content="Annotations are imported successfully!"
        onConfirm={hideUploadAnnoConfirm}
      />
      <Dialog
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal( false )}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnalysisPopUp
            style={{ width: '50%', height: 'auto' }}
            biomarkers={
              state.biomarkers?.[ state?.selectedAnno?._id ] || undefined
            }
            isFetching={isReloadBiomarkers}
            setProteinSelected={setProteinSelected}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisModal( false )}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      {isMCDFile && <Dialog
        open={openMCDAreaData}
        onClose={() => setOpenMCDAreaData( false )}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <MCDAreaDataPopUp />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenMCDAreaData( false )}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>}
      <Dialog
        open={openAnalysisGraphModal}
        onClose={() => setOpenAnalysisGraphModal( false )}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnnotationContext.Provider value={state?.selectedAnno?.stroke}>
            <AnalysisGraphPopUp
              biomarkers={
                state.biomarkers?.[ state?.selectedAnno?._id ] || undefined
              }
              slideId={MCDSlideSelectedID}
              groupId={group1Id}
              isFetching={isReloadBiomarkers}
            />
          </AnnotationContext.Provider>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphModal( false )}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisGraphCompSelectionModal}
        onClose={() => setOpenAnalysisGraphCompSelectionModal( false )}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <Autocomplete
            disableCloseOnSelect
            id="tags-outlined"
            size="small"
            options={state.slide.groups || []}
            onChange={( event, newValue ) => {
              compareWithChange( newValue );
            }}
            isOptionEqualToValue={( option, value ) => option.Name === value.Name}
            getOptionLabel={( option ) => {
              return option.Name;
            }}
            renderOption={( props, option ) => {
              if ( state?.selectedAnno?.stroke !== option.Color ) {
                return <li {...props}>{option.Name}</li>;
              }
            }}
            renderInput={( params ) => (
              <TextField
                {...params}
                sx={{
                  '& label.MuiInputLabel-shrink': {
                    color: '#4A4A4A',
                    fontWeight: '600'
                  }
                }}
                label="Compare with"
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphCompSelectionModal( false )}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisGraphCompModal}
        onClose={() => setOpenAnalysisGraphCompModal( false )}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnnotationContext.Provider value={state?.selectedAnno?.stroke}>
            <AnalysisGraphPopUpWithCompare
              biomarkers={
                state.biomarkers?.[ state?.selectedAnno?._id ] || undefined
              }
              slideId={MCDSlideSelectedID}
              group1Id={group1Id}
              group2Id={group2Id}
              compBiomarkers={state.biomarkers?.[ compareAnno?._id ] || undefined}
              isFetching={isReloadBiomarkers}
              compareAnnoStroke={compareAnno?.stroke}
            />
          </AnnotationContext.Provider>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphCompModal( false )}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={overwriteAnalysisFileAlert}
        title="Overwrite/Append Analysis Data"
        content="Are you sure you want to overwrite/append to the existing analysis Data?"
        onDismiss={() => {
          uploadAnalysisFile( false );
        }}
        onConfirm={() => {
          uploadAnalysisFile( true );
        }}
        confirmBtn="yes"
        cancelBtn="no"
      />
    </SlideViewerContext.Provider>
  );
};
export default ImageViewer;
